import React, {useCallback, useEffect, useReducer} from 'react'
import {Link} from 'react-router-dom'
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'

import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {useSubscription} from "@apollo/client";
import {orderBy} from "lodash";
import {createUseStyles} from "react-jss";
import {NOTIFICATION_PUSHED_SUBSCRIPTION} from "../constants/subscriptions/dashboard";
import { useSessionStorage} from "react-use";
import {useDebouncedEffect} from "../hooks";

const notificationContainerStyle = {
  maxHeight: '230px',
}


function reducer(currentState, newState) {
  return {...currentState, ...newState}
}


const useStyles = createUseStyles({
  notificationMsgItem: {
    padding: '10px',
    borderBottom: '1px solid lightgray'
  },

  clearAllButton:{
    marginTop: '-5px',
  }
})

const NotificationDropdown = React.memo(({companyId}) => {

  const classes = useStyles();
  const {data: notificationData} = useSubscription(NOTIFICATION_PUSHED_SUBSCRIPTION, {
    variables: {
      companyId
    }
  });

  const [lastNotificationState, setLocalStoreState] = useSessionStorage(`user-notifications`, {
    notifications: [],
  });


  const [{
    notifications,
    unreadNotificationCount,
    dropdownOpen
  }, setState] = useReducer(reducer, {
    dropdownOpen: false,
    notifications: lastNotificationState?.notifications && Array.isArray(lastNotificationState.notifications) ? lastNotificationState.notifications : [],
    unreadNotificationCount: 0,
  });


  useEffect(() => {
    if (notificationData && Object.values(notificationData).length > 0) {
      const __newNotificationData = Object.values(notificationData)[0];
      const __notifications = [...notifications];
      __notifications.push(__newNotificationData);
      setState({
        notifications: orderBy(__notifications, 'time', ['desc'])
      })
    }
    // eslint-disable-next-line
  }, [notificationData])


  useEffect(() => {
    if (notifications) {
      let __unreadNotificationCount = 0;
      notifications.forEach((item) => {
        if (item.isAcknowledged !== true) {
          __unreadNotificationCount++;
        }
      })
      setState({
        unreadNotificationCount: __unreadNotificationCount
      })
    }
  }, [notifications])

  /*:: toggleDropdown: () => void */
  const toggleDropdown = useCallback(() => {
    setState({
      dropdownOpen: !dropdownOpen,
      notifications: notifications.map(item => {
        const __newItem = {...item};
        __newItem.isAcknowledged = true;
        return __newItem;
      })
    })
  }, [dropdownOpen, notifications]);


  useDebouncedEffect(() => {
    setLocalStoreState({
      notifications
    })
  }, 400, [notifications])


  const clearAll = useCallback((event) => {
    event.stopPropagation();
    setState({
      notifications: []
    })
  }, []);


  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="notification-list"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle  waves-effect waves-light btn btn-link"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      > <i className="fe-bell noti-icon"/>
        {unreadNotificationCount > 0 && (
          <Badge className="noti-badge animate__fadeIn" color="danger" pill>
            {unreadNotificationCount}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-lg">
        <div onClick={toggleDropdown}>
          <div className="dropdown-item noti-title">
            <Row>
              <Col>
                {notifications.length > 0 ? (<h5 className="m-0"> Notifications </h5>) : (<h5 className="m-0"> No notifications available </h5>)}
              </Col>
              <Col>
                <div className="float-right">
                  {notifications.length > 0 && (
                    <Button className={classes.clearAllButton}  type="button" color="link" onClick={clearAll}>
                      <small>Clear All</small></Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <PerfectScrollbar style={notificationContainerStyle}>
            {notifications.map((item, i) => (
                <Link to={item.url} className="text-dark"
                      key={`notify-details-${i}`}>
                  <p className={classes.notificationMsgItem}>
                    <small>{item.description}</small>
                  </p>
                </Link>
              )
            )}
          </PerfectScrollbar>
        </div>
      </DropdownMenu>
    </Dropdown>
  )

});

export default NotificationDropdown
