import React from 'react'
import {
  Link,
} from 'react-router-dom'

import  GlobalContext  from '../providers/GlobalContext'


const BreadcrumbComponent = React.memo(() => {

  const { selectedCompany } = React.useContext(GlobalContext)

  if (selectedCompany){
    return (<ol className="breadcrumb m-0">
      <React.Fragment>
        <li className="breadcrumb-item">
          <Link to={`/company/${selectedCompany.id}`}>
            {selectedCompany.name}
          </Link>
        </li>
      </React.Fragment>
    </ol>)
  }
  return null;
});

export default BreadcrumbComponent
