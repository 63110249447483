import React from 'react';
import {createUseStyles} from "react-jss";
import {isNumber, round} from "lodash";

const useStyles = createUseStyles({
  weatherIconContainer:{
    display: 'inline-block',
    position: 'absolute',
    //right: 0,
    // top: 0,
    left: -5,
  },
  statsText: {
    color: '#64686b',
    textAlign: 'right',
    marginTop: '12px',
  },
  weatherIcon: {
    verticalAlign: 'top',
    marginTop: '-8px',
    marginLeft: '-5px',
    cursor: 'pointer',
  }
})

const TinyWeatherInfo = React.memo(({data})=>{
 const classes = useStyles();

  return (<div className="position-relative">
        {data?.icon && (
          <div className={classes.weatherIconContainer}>
            <img  className={classes.weatherIcon} src={`https://openweathermap.org/img/wn/${data.icon}.png`} width="30px" height="30px" alt="" />
          </div>
        )}
        {isNumber(data?.tempNow) && (
            <div className="margin-left-5">{round(data?.tempNow, 1)}°C </div>
        )}
  </div>)

})

export default TinyWeatherInfo;
