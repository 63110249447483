import React, { useEffect, useReducer } from 'react'
import { createUseStyles } from 'react-jss'
import { useDropzone } from 'react-dropzone'
import * as classnames from 'classnames'
import { Button, Media } from 'reactstrap'
import fileHelper from '../../helpers/fileHelper'

function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

const useStyles = createUseStyles({
  dropZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  isDragActive: {
    borderColor: '#2196f3',
  },
  isDragAccepted: {
    borderColor: '#00e676',
  },
  isDragRejected: {
    borderColor: '#ff1744',
  },
  fileMedia: {
    width: 'auto',
    height: '150px',
    animation: 'animate-base-container 850ms forwards',
  }
})

const FileUploadFormItem = React.memo(({name, setFieldValue, accept, value})=>{
  const classes = useStyles();
  const {
    getRootProps, getInputProps, open, acceptedFiles, isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept || {'image/*': [".png",".jpg",".gif",".jpeg",".svg",".tiff"]},
    noKeyboard: true,
    noClick: true,
  });

  const [{fileSrc}, setState] = useReducer(reducer, {
    fileSrc: undefined,
  })

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFieldValue(name, acceptedFiles[0]);
    }
  }, [acceptedFiles, setFieldValue, name]);


  useEffect(()=>{
    if (value){
      if(value instanceof File){
        fileHelper.parseFileAsUrl(acceptedFiles[0]).then((data)=>{
          setState({
            fileSrc: data
          })
        })
      } else if (value.url){
        setState({
          fileSrc: value.url
        })
      }
      return ()=>{
        setState({
          fileSrc: undefined
        })
      }
    }
    // eslint-disable-next-line
  },[value, setFieldValue])


  return (<div  {...getRootProps({ className: classnames(classes.dropZone, isDragActive && classes.isDragActive, isDragAccept && classes.isDragAccepted, isDragReject && classes.isDragRejected) })}>
    <input {...getInputProps()} />
    {fileSrc && (
      <Media className={classes.fileMedia}  src={`${fileSrc}`} alt={name} />
    )}
    <p>Drag 'n' drop some files here, or click to select files</p>
    <Button outline color="light" onClick={open} className=""
            size="sm">
      Upload
    </Button>
  </div>);


});

export default FileUploadFormItem;
