import React, { useCallback, useEffect } from 'react'
import packageJson from '../../package.json'
import { toast } from 'react-toastify'

global.appVersion = packageJson.version

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)
  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())
    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

const VersionCheck = React.memo(({children})=> {

  const refreshCacheAndReload = useCallback(() => {
    const _promises = [];
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names)=> {
        for (let name of names) {
          _promises.push(caches.delete(name));
        }
        return Promise.all(_promises);
      }).then(()=>{
        if (sessionStorage){
          sessionStorage.clear();
        }
        return;
      }).then(()=>{
        if (localStorage){
          const keys = Object.keys(localStorage);
          for (const key of keys){
            if (key && (!key.startsWith("token") && !key.startsWith("refreshToken"))){
              localStorage.removeItem(key);
            }
          }
        }
        return;
      }).then(()=>{
        // delete browser cache and hard reload
        window.location.reload(true)
      }).catch((err)=>{
        console.error('Failed at refreshCacheAndReload', err);
      })
    }
  }, []);

  useEffect(()=>{
    const vUrl = `/version.json?_dc=${Date.now()}`
    fetch(vUrl, { cache: "no-cache"})
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version
        const currentVersion = global.appVersion
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
        if (shouldForceRefresh) {
          toast('An update is ready. Reloading the page in few seconds.',{
            type: "info",
            autoClose: 5000,
            onClose: ()=>{
              // eslint-disable-next-line
              refreshCacheAndReload();
            }
          })
        }
      })
      .catch((err)=>{
        console.error('Failed to fetch version.json', err);
      })
    // eslint-disable-next-line
  }, []);


  return (children);
})

export default VersionCheck
