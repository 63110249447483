import React, {useEffect, useMemo, useReducer} from 'react'
import {Link} from 'react-router-dom'
import {sortBy} from 'lodash'
import {Table} from './Table'
import Loader from '../loaders/Loader'


function reducer(currentState, newState) {
  return {...currentState, ...newState}
}

const SiteTable = React.memo(function SiteTable({
                                                  companyId,
                                                  loading,
                                                  sites,
                                                  onRowHover
                                                }) {

  const [{data}, setState] = useReducer(reducer, {
    data: [],
  });

  useEffect(() => {
    if (sites) {
      setState({
        data: sortBy(sites, (item) => item.stats?.incidents?.open).reverse(),
      });
    }
  }, [sites]);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({row}) => {
        const {original} = row
        return (
          <Link
            to={!row.depth ? `/company/${companyId}/site/${original.id}` : `/company/${companyId}/zone/${original.id}`}
            className="tableIcons">
            {original.name}
          </Link>
        )
      },
    },
    {
      Header: 'Zones',
      accessor: 'zones.count',
      Cell: ({row}) => {
        const {original} = row
        return !row.depth ? (
          <Link to={`/company/${companyId}/site/${original.id}`}>
            {original.zones && original.zones.count}
          </Link>
        ) : (
          <Link to={`/company/${companyId}/zone/${original.id}`}>
            -
          </Link>
        )
      },
    },
    {
      Header: 'Assets',
      accessor: 'assets.count',
      Cell: ({row}) => {
        const {original} = row
        return !row.depth ? (
          <Link to={`/company/${companyId}/assets`}>
            {original.assets && original.assets.count}
          </Link>
        ) : (
          <Link to={`/company/${companyId}/assets`}>
            {original.assets && original.assets.count}
          </Link>
        )
      },
    },
    {
      Header: 'Incidents',
      accessor: 'stats.incidents.open',
      Cell: ({row}) => {
        const {original} = row
        return (
          <Link
            to={!row.depth ? `/company/${companyId}/incidents?siteId=${original.id}` : `/company/${companyId}/incidents?zoneId=${original.id}`}>
            {original.stats?.incidents?.open}
          </Link>
        )
      },
    },
    {
      Header: 'Status',
      accessor: (row) => row.stats?.incidents && row.stats?.incidents.open ? 'status-1' : 'status-0',
      Cell: ({row}) => {
        const {original} = row
        if (!original.stats?.incidents){
          return null;
        }
        return (
          <Link
            to={!row.depth ? `/company/${companyId}/incidents?siteId=${original.id}` : `/company/${companyId}/incidents?zoneId=${original.id}`}
            className={`tableIcons ui horizontal label label-size ${original.stats.incidents.open > 0 ? 'red' : 'green'}`}>
          <span
            >
            {original.stats?.incidents.open > 0 ? 'Incident' : 'Good'}
          </span>
          </Link>
        )
      },
    },
    {
      Header: 'Expand',
      id: 'expander',
      Cell: ({row}) => !row.depth ? (
        <span
          {...row.getToggleRowExpandedProps()}
          onMouseDown={e => e.preventDefault()}
        >
        {row.isExpanded ? (
          <h5><i className="fe-chevron-up"/></h5>
        ) : (
          <h5><i className="fe-chevron-down"/></h5>
        )}
      </span>
      ) : null,
    },
  ], [companyId]);

  return (
    <div className="newTable overflow-auto">
      {loading && <Loader/>}
      <Table
        data={data}
        columns={columns}
        getSubRows={(row) => (row.zones && row.zones.nodes.map(_ => ({
          ..._,
          parentId: row.id
        }))) || []}
        defaultPageSize={10}
        onRowHover={onRowHover}
      />
    </div>
  )
});

export default SiteTable
