import { useLoadScript } from '@react-google-maps/api'
import { useCallback, useReducer, useRef } from 'react'

const DefaultConfig = {
  defaultCenter: {
    lat: 52.564887, lng: -1.8216767,
  },
  defaultZoom: 19,
  infoDivStyle: {
    background: `white`,
    minWidth: '300px',
  },
  pathOptions: {
    strokeColor: '#530eea',
    strokeOpacity: 0.4,
    strokeWeight: 6,
    fillColor: '#c90eea',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    // paths: __paths,
    zIndex: 1,
  },
}

const defaultLibraries = ['places'];


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useSematicsGoogleMap() {

  const mapRef = useRef()

  const { isLoaded } = useLoadScript({
    version: '3',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries : defaultLibraries,
  });

  const [{ pathCoords, markers, selectedMarkerData, mapReady }, setState] = useReducer(reducer, {
    pathCoords: [],
    markers: [],
    selectedMarkerData: undefined,
    mapReady: false,
  })

  const onMapLoadCallback = useCallback(function onMapLoadCallback(map) {
    if (map) {
      mapRef.current = map
      setState({
        mapReady: true,
      })
    }
  }, [])

  const onMarkerClick = useCallback((markerItem) => {
    setState({
      selectedMarkerData: { ...markerItem },
    })
  }, [])

  const onMarkerClose = useCallback(() => {
    setState({
      selectedMarkerData: undefined,
    })
  }, [])

  const onMapClick = useCallback(() => {
    setState({
      selectedMarkerData: undefined,
    })
  }, [])


  return {
    isMapLoaded: isLoaded,
    onMarkerClick,
    onMapClick,
    onMarkerClose,
    onMapLoadCallback,
    DefaultConfig,
    pathCoords,
    markers,
    selectedMarkerData,
    mapReady,
    mapRef,
  }

}


export default useSematicsGoogleMap;
