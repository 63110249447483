import React, {
  useCallback,
  useContext,
  useLayoutEffect
} from 'react';
import GlobalContext from "../../providers/GlobalContext";
import CustomReportGeneratorModal from "./CustomReportGeneratorModal";


const CustomReportGenerator = React.memo(function CustomReportGenerator(_, ref) {

  const {
    showGlobalCustomReportModal,
    setShowGlobalCustomReportModal
  } = useContext(GlobalContext)

  const _listenKey = useCallback((event) => {
    const isEscape = event.keyCode === 27;
    if (isEscape) {
      setShowGlobalCustomReportModal(false);
      event.preventDefault();
      return;
    }
    const isCtrlR = event.keyCode === 69 && event.ctrlKey
    if (!isCtrlR) {
      return
    }
    setShowGlobalCustomReportModal(true);
    event.preventDefault();
  }, [setShowGlobalCustomReportModal])

  useLayoutEffect(() => {
    document.body.addEventListener('keydown', _listenKey)
    return () => {
      document.body.removeEventListener('keydown', _listenKey)
    }
  }, [_listenKey])

  if (showGlobalCustomReportModal === true) {
    return (
      <CustomReportGeneratorModal isOpen={showGlobalCustomReportModal}
                                  setIsOpen={setShowGlobalCustomReportModal}/>

    )
  }
  return null;
})


export default CustomReportGenerator;

