import React from 'react'
import { Row, Col } from 'reactstrap'

const ErrorPage = () =>
  <div className="container-fluid top-space">
    <Row>
      <Col lg={12} className="error-page">
        <span role="img" aria-label="hello" className="randomQuote-emoji">🚒</span><br />
        <h3>We're sorry something has gone wrong</h3>
        <p>If the problem persists, please contact support.</p>
      </Col>
    </Row>
  </div>

export default ErrorPage