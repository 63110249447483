import {
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { ValidationSchema } from './UpdateProfileFields'
import GlobalContext from '../../../providers/GlobalContext'
import useHandleInitialUpdateProfileData
  from "./useHandleInitialUpdateProfileData";
import {MANAGE_UPDATE_USER_PROFILE_MUTATION} from "../../../constants/mutations/manage";
import { toast } from 'react-toastify'


function useUpdateProfileForm({setIsOpen}) {

  const client = useApolloClient();
  const { loadLoggedInUser} = useContext(GlobalContext);
  const timerRef = useRef(null);

  const {initialValues, initialised } = useHandleInitialUpdateProfileData();

 const [updateProfileRequest] = useMutation(MANAGE_UPDATE_USER_PROFILE_MUTATION);

  const processUpdateProfile = useCallback(async function processUpdateProfile(setSubmitting, finalData) {
    setSubmitting(true)
    const result = await updateProfileRequest({ variables: finalData })
    setSubmitting(false)
    if (result && result.data && result.data.updateUserProfile) {
      if (result.data.updateUserProfile.success === true) {
        await client.clearStore();
        loadLoggedInUser();
        toast(`Your profile has been updated successfully`, {
          type: toast.TYPE.SUCCESS
        })
        setIsOpen(false);
      } else {
        const errorMessage =  Object.values(result.data)[0] && Object.values(result.data)[0].message ? Object.values(result.data)[0].message : 'Failed Request';
        toast(errorMessage, {
          type: 'error'
        });
      }
    }
    // eslint-disable-next-line
  }, [updateProfileRequest])

  const formikOptions = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    initialValues: {
      firstName: initialValues.firstName ?? '',
      lastName: initialValues.lastName ?? '',
      telephone: initialValues.telephone ?? '',
      profilePictureFile: initialValues.profilePictureFile ?? '',
      emailPasscodeEnabled: initialValues.emailPasscodeEnabled ?? true,
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const finalData = {
        ...initialValues,
        ...values,
      }
      if (finalData.profilePictureFile instanceof File === false) {
        finalData.profilePictureFile = undefined;
      }
      try {
        await processUpdateProfile(setSubmitting, finalData);
      } catch (err) {
        console.log(err)
        if (err && err.message) {
          toast(err.message, {
            type: 'error'
          });
        }
      }
    },
  });


  useEffect(()=>{
    return ()=>{
      if(timerRef && timerRef.current){
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }
    // eslint-disable-next-line
  }, [])

  return {formikOptions, initialised }

}


export default useUpdateProfileForm
