import React, { useEffect, useReducer } from "react";
import {
  useParams, useNavigate, useLocation,
} from 'react-router-dom';
import { useLazyQuery } from "@apollo/client";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isFunction } from 'lodash';
import ErrorPage from "../ErrorPage";
import CompanyOverview from "./Company/Overview";
import {
  GET_COMPANY_MINIMAL_QUERY, GET_COMPANY_STATS_QUERY
} from "../../constants/queries/dashboard";
import GlobalContext from "../../providers/GlobalContext";
import urlHelper from "../../helpers/urlHelper";
import { useDebouncedEffect, useJsonCompareEffect } from "../../hooks";
import NotFoundPage from "../NotFoundPage";
import VersionCheck from '../../utils/versionCheck';
import commonReducer from '../../common/commonReducer';



function useCompanyOverview() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const {
    loggedInUser,
    selectActiveSessionCompany
  } = React.useContext(GlobalContext);

  const [getCompanyQuery, {
    data: companyData,
    error,
    called,
    loading
  }] = useLazyQuery(GET_COMPANY_MINIMAL_QUERY, {});

  const [getCompanyStatsQuery, {stopPolling: stopCompanyStatsPooling}] = useLazyQuery(GET_COMPANY_STATS_QUERY, {
    pollInterval: 30000,
  });

  const [{ companyId, company, showNotfoundCmp }, setState] = useReducer(commonReducer, {
    companyId: params.companyId,
    company: undefined,
    showNotfoundCmp: false,
  });

  useEffect(()=>{
    if (isFunction(stopCompanyStatsPooling)) {
      return () => {
        if (isFunction(stopCompanyStatsPooling)) {
          stopCompanyStatsPooling();
        }
      }
    }
  },[stopCompanyStatsPooling])


  useEffect(() => {
    if (loggedInUser && companyId && ["GLOBAL_ADMIN", "REPORT"].includes(loggedInUser.role) === false && loggedInUser.companyId?.toString() !== companyId?.toString()) {
      const _comId = parseInt(companyId.toString());
      if (isFinite(_comId)) {
        if (loggedInUser.permissions && Array.isArray(loggedInUser.permissions)) {
          const foundIndex = loggedInUser.permissions.findIndex(item => item?.companyId?.toString() === companyId.toString());
          if (foundIndex > -1) {
            selectActiveSessionCompany(companyId);
            let reloadAttempt = 0;
            const reloadAttemptStr = urlHelper.extractFromUrl("reloadAttempt", location);
            if (reloadAttemptStr && !isNaN(parseInt(reloadAttemptStr))) {
              reloadAttempt = parseInt(reloadAttemptStr);
            }
            if (reloadAttempt < 3) {
              reloadAttempt++;
              const _params = new URLSearchParams(location.search);
              _params.set("reloadAttempt", reloadAttempt);
              const __url = `${location.pathname}?${_params.toString()}`;
              setTimeout(() => {
                window.location.replace(location.origin + __url);
              }, 2000);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [companyId]);

  useDebouncedEffect(() => {
    if (companyId) {
      getCompanyQuery({
        variables: {
          id: params.companyId
        }
      });
    }
    // eslint-disable-next-line
  }, 10, [companyId]);


  useDebouncedEffect(() => {
    if (companyId) {
      getCompanyStatsQuery({
        variables: {
          id: companyId
        }
      });
    }
    // eslint-disable-next-line
  }, 700,[companyId]);

  useEffect(() => {
    if (params && params.companyId) {
      if (companyId){
        if (companyId.toString() !== params.companyId.toString()){
          setState({
            companyId: params.companyId.toString()
          });
        }
      } else {
        setState({
          companyId: params.companyId.toString()
        });
      }
    } else if (loggedInUser) {
      if (loggedInUser.companyId && parseInt(loggedInUser.companyId) > 0) {
        navigate(`/company/${loggedInUser.companyId}`);
      } else {
        navigate("/company/2");
      }
    }
    // eslint-disable-next-line
  }, [params, loggedInUser]);

  useJsonCompareEffect(() => {
    if (companyData) {
      setState({
        company: companyData.company
      });
    }
  }, [companyData]);


  useDebouncedEffect(()=>{
    if (!loading && called && !company){
     setState({
       showNotfoundCmp: true
     })
    } else {
      setState({
        showNotfoundCmp: false
      })
    }
  }, 700, [loading, called, company])


  return {
    loggedInUser,
    companyData,
    error,
    called,
    loading,
    companyId,
    company,
    showNotfoundCmp,
    setState
  };

}


const DashboardCompany = React.memo(() => {

  const {
    error,
    companyId,
    showNotfoundCmp,
  } = useCompanyOverview();

  if (error) {
    return <ErrorPage />;
  }

  if (showNotfoundCmp){
    return (<NotFoundPage />)
  }

  return (
    <React.Fragment>
      {companyId ? (
        <CompanyOverview
          companyId={companyId}
        />
      ): null}
      <VersionCheck />
    </React.Fragment>
  );
});

export default DashboardCompany;
