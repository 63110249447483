import React, { useCallback, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'
import  GlobalContext  from '../providers/GlobalContext'


const ProfileDropdownItem = React.memo(({ menu, dropdownOpen, onNavigate, toggleDropdown }) => {

  if (menu?.children && Array.isArray(menu?.children)) {
    return (
      <Dropdown
        isOpen={dropdownOpen && dropdownOpen[menu.id] && dropdownOpen[menu.id] === true}
        toggle={() => toggleDropdown(menu.id)}>
        <DropdownToggle
          data-toggle="dropdown"
          tag="div"
          // onClick={()=> toggleDropdown(menu.id)}
          aria-expanded={dropdownOpen && dropdownOpen[menu.id] && dropdownOpen[menu.id] === true}
        >
          <DropdownItem className="dropdown-item notify-item niceSize">
            <span>{menu.label} </span>
          </DropdownItem>
        </DropdownToggle>
        <DropdownMenu end>
          <div>
            {menu?.children.map((item, index) => {
              return (
                <React.Fragment key={`${index}-profile-sub-menu`}>
                  <DropdownItem onClick={() => onNavigate(item)}>
                    <span>  <i className={`${item.icon} mr-1`}/> {item.label}</span>
                  </DropdownItem>
                </React.Fragment>
              )
            })}
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }

  if (menu?.hasDivider) {
    return <DropdownItem divider/>
  }

  if (menu) {
    return (<DropdownItem className="dropdown-item notify-item niceSize"
                          onClick={() => onNavigate(menu)}>
      <span> <i className={`${menu.icon} mr-1`}/> {menu.label}</span>
    </DropdownItem>)
  }
  return null
})


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

const ProfileDropdown = React.memo(function({ menuItems, username }) {

  const navigate = useNavigate()

  const { loggedInUser } = React.useContext(GlobalContext)

  const [{ dropdownOpen }, setState] = useReducer(reducer, {
    dropdownOpen: {},
  })

  const toggleDropdown = useCallback((id) => {
    let _dropdownOpen = { ...dropdownOpen }
    _dropdownOpen[id] = !_dropdownOpen[id]
    if (id !== 0) {
      let requireRootCollapse = true
      const _keys = Object.keys(_dropdownOpen)
      for (const key of _keys) {
        if (key && parseInt(key) !== 0) {
          if (_dropdownOpen[key] === true) {
            requireRootCollapse = false
            break
          }
        }
      }
      if (requireRootCollapse) {
        _dropdownOpen = {}
      }
    }
    setState({
      dropdownOpen: _dropdownOpen,
    })
  }, [dropdownOpen])


  const onNavigate = useCallback((menuItem) => {
    if (menuItem && menuItem.target) {
      window.open(menuItem.redirectTo, menuItem.target)
    } else if (menuItem && menuItem.redirectTo) {
      navigate(menuItem.redirectTo)
    } else if (menuItem.onClick){
      menuItem.onClick();
    }
  }, [navigate])

  if (!loggedInUser) {
    return null
  }

  return (
    <Dropdown
      isOpen={dropdownOpen && dropdownOpen[0] && dropdownOpen[0] === true}
      toggle={() => toggleDropdown(0)}
      className="notification-list"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={() => toggleDropdown(0)}
        aria-expanded={dropdownOpen && dropdownOpen[0] && dropdownOpen[0] === true}
      >
        <div className="userCircle">{username}</div>
      </DropdownToggle>
      <DropdownMenu end className="topbar-dropdown-menu profile-dropdown">
        <div>
          {menuItems && menuItems.map((item, i) => {
            return (
              <React.Fragment key={`${i}-profile-menu`}>
                <ProfileDropdownItem menu={item} onNavigate={onNavigate}
                                     dropdownOpen={dropdownOpen}
                                     toggleDropdown={toggleDropdown}/>
              </React.Fragment>
            )
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  )
})

export default ProfileDropdown
