import { useContext, useEffect } from 'react'
import useProgressiveDataLoader from './useProgressiveDataLoader'
import { GET_TELEMETRY_KPI_SETTINGS_LOOKUP_QUERY } from '../constants/queries/lookup'
import  GlobalContext  from '../providers/GlobalContext'


function useTelemetryKpiSettingsLookupData() {

  const { fetchData: fetchKpiSettingsData, data: kpiSettingsLookupData } = useProgressiveDataLoader({query: GET_TELEMETRY_KPI_SETTINGS_LOOKUP_QUERY });

  const { selectedCompany } = useContext(GlobalContext);

  useEffect(()=>{
    if (selectedCompany && selectedCompany.id){
      fetchKpiSettingsData({
        variables: {
          companyId: selectedCompany.id,
          page: 1
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedCompany?.id]);


  return {
    kpiSettingsLookupData
  }

}



export default useTelemetryKpiSettingsLookupData;
