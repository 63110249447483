import React, { useContext } from 'react'
import SpotlightContext from './SpotlightContext'
import { Button } from 'reactstrap'

const SearchCloseButton = React.memo(() =>{

  const { closeSearchBar } = useContext(SpotlightContext);

  return (
    <Button style={{ position: 'absolute', right: '10px', top: '35%'}} close type="button" onClick={closeSearchBar}  aria-label="Close" />
  );
});

export default SearchCloseButton;


