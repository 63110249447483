import * as am4core from '@amcharts/amcharts4/core'

function useSematicsTheme(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color("#064e40"),
        am4core.color("#0dad8d"),
        am4core.color("#8dd8cc"),
        am4core.color("#30bfbf"),
        am4core.color("#0c98ba"),
        am4core.color("#1164b4"),
      ];
    }
  }

export default useSematicsTheme;
