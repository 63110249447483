import React, {useCallback, useContext, useLayoutEffect, useRef} from 'react'
import {createUseStyles} from "react-jss";
import * as classnames from 'classnames';
import { isNumber, noop } from 'lodash'
import SpotlightContext from './SpotlightContext'

const useStyles = createUseStyles({
  list:{
    width: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    userSelect: 'none',
    position: 'absolute',
    height: 'calc(100% - 55px)',
    borderRight: '1px solid #515253',
    backgroundColor: '#EBEBEB',
  },
  ul: {
    minHeight: 'calc(100% - 55px)',
    margin: '0',
    padding: '0',
    width: '100%',
    listStyleType: 'none',
  },
  li: {
    margin: '0',
    padding: '0',
    width: '100%',
    listStyleType: 'none',
  },
  resultHeader:{
    color: '#ffffff',
    fontSize: '12px',
    padding: '4px 0 2px 25px',
    textTransform: 'uppercase',
    backgroundColor: 'rgba(53, 75, 84, 0.97)',
  },
  hitLi: {
    cursor: 'pointer',
    fontSize: '12px',
    padding: '6px 6px 6px 25px !important',
  },
  hitLiSelected: {
    color: '#ffffff',
    backgroundColor: '#5AC6D2',
  }
});


function easeOutCubic(t, b, c, d) {
  // eslint-disable-next-line no-return-assign
  return c * ((t = t / d - 1) * t * t + 1) + b;
}


const HitListComponent = React.memo(() => {

  const classes = useStyles();
  const {hits, selectedResultIndex, selectHit} = useContext(SpotlightContext);
  const listRef  = useRef();

  const animatedScrollTo = useCallback((
    element,
    to,
    duration = 200,
    callback = noop
  ) => {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 10;
    let currentTime = 0;

    function animateScroll() {
      currentTime += increment;
      const val = easeOutCubic(currentTime, start, change, duration);
      // eslint-disable-next-line no-param-reassign
       element.scrollTop = val;
      if (currentTime < duration) {
        window.requestAnimationFrame(animateScroll);
      } else {
        callback(element);
      }
    }
    animateScroll();
  }, []);

  useLayoutEffect(()=>{
    if (listRef.current && isNumber(selectedResultIndex)){
      const selectedEl = listRef.current.querySelector(`.hit-list-item-${selectedResultIndex}`);
      if(selectedEl) {
        let expectedScrollTop;
        const menuRect = listRef.current.getBoundingClientRect();
        const focusedRect = selectedEl.getBoundingClientRect();
        const overScroll = selectedEl.offsetHeight / 3;
        if (focusedRect.bottom + overScroll > menuRect.bottom) {
          expectedScrollTop = Math.min(
            selectedEl.offsetTop +
            selectedEl.clientHeight -
            listRef.current.offsetHeight +
            overScroll,
            listRef.current.scrollHeight
          )
        } else if (focusedRect.top - overScroll < menuRect.top) {
          expectedScrollTop = Math.max(selectedEl.offsetTop - overScroll, 0);
        }
        if (isNumber(expectedScrollTop)) {
          animatedScrollTo(listRef.current, expectedScrollTop);
        }
      }
    }
    // eslint-disable-next-line
  },[listRef, selectedResultIndex]);



  return (
    <div ref={listRef} className={classes.list}>
      <ul className={classes.ul}>
        {Object.keys(hits).map(hitKey => {
          if (hits[hitKey].length === 0) {
            return null
          }
          return (
            <li key={`hit-group-${hitKey}`} className={classes.li}>
              <div className={classes.resultHeader}>{hitKey}</div>
              <ul className={classes.ul}>
                {hits[hitKey].map(hit => (
                  <li
                    className={classnames(classes.li, `hit-list-item-${hit.flatIndex}` , classes.hitLi,
                        hit.flatIndex === selectedResultIndex && classes.hitLiSelected
                    )}
                    key={`hit-${hit.id}`}
                    onClick={() => selectHit(hit.flatIndex)}
                  >
                    {hit.name}
                  </li>
                ))}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
})


export default HitListComponent
