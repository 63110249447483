import axiosClient from './axiosClient'
import { setSession } from '../helpers/authUtils'


const REFRESH_AUTH_TOKEN = `
    mutation refreshAuthToken($refreshToken: String!) {
            refreshAuthToken(input: { refreshToken: $refreshToken }) {
                success
                token
                refreshToken
                expiresIn
                message
                user {
                  id
                  email
                  firstName
                  lastName
                  role
                  companyId
                  isEndUserAgreementAccepted
                  passwordExpiresAt
                  permissions {
                    id
                    companyId
                  }
                }
            }
    }`

const refreshAuthToken = async ({token, refreshToken})=> {
  try {
    let result = await axiosClient.fetchData(REFRESH_AUTH_TOKEN, {
      refreshToken
    }, token);
    if (result && result.refreshAuthToken) {
      if (result.refreshAuthToken.success === true) {
        setSession(result.refreshAuthToken);
        return result.refreshAuthToken;
      } else {
        throw new Error(result.refreshAuthToken.message || 'Error refreshing auth token');
      }
    }
    return null;
  }catch (err){
    console.log('error at refreshAuthToken', err)
    throw err;
  }

}


export default refreshAuthToken;
