import React, { useContext } from 'react'
import SearchIcon from "./SearchIcon";
import SearchInput from "./SearchInput";
import SearchClose from "./SearchClose";
import SpotlightContext from './SpotlightContext'
import SearchBusyIcon from './SearchBusyIcon'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  searchBar: {
    zIndex: 10,
    height: '60px',
    position: 'relative',
    backgroundColor: "#EBEBEB"
  }
})

const SearchBar = React.memo(() => {
  const classes = useStyles();
  const { loading } = useContext(SpotlightContext);

  return (
    <div className={classes.searchBar}>
      {loading? <SearchBusyIcon/> :  <SearchIcon />}
      <SearchInput />
      <SearchClose />
    </div>
  );
});

export default SearchBar;
