import React, { useEffect, useReducer, useRef } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import useSematicsTheme from './useSematicsTheme';
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

function reducer (currentState, newState) {
  return { ...currentState, ...newState }
}


function RadialChart ({ series }) {
  const [{ data }, setState] = useReducer(reducer, {
    data: series || [],
  })

  let chartObj = useRef()

  useEffect(() => {
    am4core.useTheme(useSematicsTheme);
    am4core.useTheme(am4themes_animated)

    let chart = chartObj.current = am4core.create('radial', am4charts.RadarChart)

    chart.data = [...data]
    chart.startAngle = -90
    chart.endAngle = 180
    chart.innerRadius = am4core.percent(20)

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.fontWeight = 200
    categoryAxis.renderer.labels.template.fontSize = 11
    categoryAxis.renderer.labels.template.adapter.add('fill', function(fill, target) {
      return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill
    })
    categoryAxis.renderer.minGridDistance = 10

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.strictMinMax = true
    valueAxis.disabled = true

    // Create series
    let series1 = chart.series.push(new am4charts.RadarColumnSeries())
    series1.dataFields.valueX = 'full'
    series1.dataFields.categoryY = 'category'
    series1.clustered = false
    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground')
    series1.columns.template.fillOpacity = 0.08
    series1.columns.template.cornerRadiusTopLeft = 4
    series1.columns.template.strokeWidth = 0
    series1.columns.template.radarColumn.cornerRadius = 4

    let series2 = chart.series.push(new am4charts.RadarColumnSeries())
    series2.dataFields.valueX = 'value'
    series2.dataFields.categoryY = 'category'
    series2.clustered = false
    series2.columns.template.strokeWidth = 0
    series2.columns.template.tooltipText = '{category}: [bold]{value}[/]'
    series2.columns.template.radarColumn.cornerRadius = 4

    series2.columns.template.adapter.add('fill', function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index)
    })

    chart.cursor = new am4charts.RadarCursor()

    return () => chart && chart.dispose()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setState({data: series})
  }, [series])

  useEffect(() => {
    if (chartObj.current) {
      chartObj.current.data = [...data]
    }
  }, [data])

  return <div id="radial" style={{ width: '100%', height: '280px' }}/>
}

export default RadialChart
