import React, { useContext, useLayoutEffect} from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import AcceptInvitation from './accept-invitation/AcceptInvitation';
import GlobalContext from "../../providers/GlobalContext";


const getYear = ()=> {
  return new Date().getFullYear();
}

const  InvitationContainer = React.memo(()=> {

  const {loggedInUser} = useContext(GlobalContext);

  useLayoutEffect(()=>{
      document.body.classList.add('authentication-bg')
      document.body.classList.add('authentication-bg-pattern');
   // clearSession();
    return ()=>{
      document.body.classList.remove('authentication-bg')
      document.body.classList.remove('authentication-bg-pattern')
    }
  }, [loggedInUser]);


    return (
      <React.Fragment>
          <div className="account-pages mt-6 mb-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card>
                    <CardBody className="position-relative">
                     <AcceptInvitation/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="col-12 text-center">
                  <p>
                    <Link to="/forget-password" className="text-white-50 ml-1">
                      Forgot your password?
                    </Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        <footer className="footer footer-alt">
         {getYear()} &copy;{' '}
          <a rel="noopener noreferrer" href="https://sematics.com" target="_blank">
            <span className="text-white-50">Sematics&#8482; Ltd</span>
          </a>
        </footer>
      </React.Fragment>
    )
})


export default InvitationContainer;
