import React from 'react'
import styled from 'styled-components'

const SearchBusyIcon = styled.div`
  width: 55px;
  height: 55px;
  margin: 12px 0 0 18px;
  position: absolute;
  background-size: cover;
  i.icon {
     border-color: transparent !important;
     border-width: 3px !important;
     border-left-color: #64686b !important;
  }
`;

export default () => (
  <SearchBusyIcon>
    <h2><i className="spinner icon" /></h2>
  </SearchBusyIcon>
);
