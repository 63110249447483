import React, { Suspense } from 'react'
import Loader from './loaders/Loader'

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
// const Footer = React.lazy(() => import("./Footer"));


const NonAuthLayout  = React.memo(({children})=> {
    return (<Suspense fallback={<Loader/>}>{children}</Suspense>)
})

export default NonAuthLayout;
