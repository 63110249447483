import { isFinite, round } from 'lodash';
import {
  dateDiff,
  dateFormatter,
  humanizeDurationAsLong,
} from './commonHelper';
import { transformMentionText } from './mentionsParserHelper';

export const transformIncidentCsvRow = (item) => {
  let worstVal = '';
  if (item.type === 'offline') {
    worstVal = 'N/A';
  } else if (isFinite(item.worstValue)) {
    worstVal = `${round(item.worstValue, 2)} ${item.unit}`;
  }

  let duration = '';
  if (item.openedAt) {
    duration = `${dateDiff(
      item.openedAt * 1000,
      item.closedAt ? item.closedAt * 1000 : new Date(),
    )}`;
  }

  let start = '',
    end = '';

  if (item.openedAt) {
    start = dateFormatter(item.openedAt * 1000);
  }

  if (item.closedAt) {
    end = dateFormatter(item.closedAt * 1000);
  }

  let acknowledgedBy = '';

  if (item.isAcknowledged && item.acknowledgedByUser) {
    acknowledgedBy =
      item.acknowledgedByUser.firstName &&
      item.acknowledgedByUser.lastName &&
      [
        item.acknowledgedByUser.firstName,
        item.acknowledgedByUser.lastName,
      ].join(' ');
  }
  if (item.isAcknowledged && item.acknowledgedByContact) {
    acknowledgedBy = item.acknowledgedByContact.name;
  }
  if (item.isAcknowledged && item.acknowledgedByControlDevice) {
    acknowledgedBy = item.acknowledgedByControlDevice.controlDeviceName;
  }

  let acknowledgedAt = '';
  if (item.isAcknowledged && item.acknowledgedAt) {
    acknowledgedAt = dateFormatter(item.acknowledgedAt * 1000);
  }

  let timeToAcknowledge = '';

  if (item.isAcknowledged && item.acknowledgedAt) {
    const duration = item.acknowledgedAt - item.openedAt;
    if (duration > 0) {
      timeToAcknowledge = humanizeDurationAsLong(duration, 's');
    }
  }

  let rootCauseDescription = '';
  let rootCauseType = '';
  let rootCauseUserName = '';
  let rootCauseTime = '';

  let correctiveDescription = '';
  let correctiveActionType = '';
  let correctiveUserName = '';
  let correctiveTime = '';

  let preventiveDescription = '';
  let preventiveActionType = '';
  let preventiveUserName = '';
  let preventiveTime = '';

  const events = item.events;
  const titleRegex = new RegExp(/Comment by/i);
  events.forEach((eventItem) => {
    if (eventItem.metadata && Array.isArray(eventItem.metadata)) {
      eventItem.metadata.forEach((meta) => {
        if (
          meta.name &&
          meta.value &&
          meta.name.toString().toLowerCase() === 'tag'
        ) {
          const _val = meta.value.toString().toLowerCase();
          if (_val === 'root cause') {
            rootCauseDescription = transformMentionText(eventItem.description);
            if (eventItem.title) {
              rootCauseUserName = eventItem.title
                .replace(titleRegex, '')
                .trim();
            }
            rootCauseTime = dateFormatter(eventItem.time);
          } else if (_val === 'corrective action') {
            correctiveDescription = transformMentionText(eventItem.description);
            if (eventItem.title) {
              correctiveUserName = eventItem.title
                .replace(titleRegex, '')
                .trim();
            }
            correctiveTime = dateFormatter(eventItem.time);
          } else if (_val === 'preventive action') {
            preventiveDescription = transformMentionText(eventItem.description);
            if (eventItem.title) {
              preventiveUserName = eventItem.title
                .replace(titleRegex, '')
                .trim();
            }
            preventiveTime = dateFormatter(eventItem.time);
          }
        }
      });
    }
    if (eventItem.incidentRootCauseType?.name) {
      rootCauseType = eventItem.incidentRootCauseType.name;
    }
    if (eventItem.incidentCorrectiveActionType?.name) {
      correctiveActionType = eventItem.incidentCorrectiveActionType.name;
    }
    if (eventItem.incidentPreventiveActionType?.name) {
      preventiveActionType = eventItem.incidentPreventiveActionType.name;
    }
  });

  return {
    start,
    end,
    worstVal,
    duration,
    acknowledgedBy,
    acknowledgedAt,
    timeToAcknowledge,
    rootCauseDescription,
    rootCauseType,
    rootCauseUserName,
    rootCauseTime,
    correctiveDescription,
    correctiveActionType,
    correctiveUserName,
    correctiveTime,
    preventiveDescription,
    preventiveActionType,
    preventiveUserName,
    preventiveTime,
  };
};
