import React from 'react'
import styled from 'styled-components'

const SearchIcon = styled.div`
  width: 55px;
  height: 55px;
  margin: 12px 0 0 18px;
  position: absolute;
  background-size: cover;
`;

export default () => (
  <SearchIcon>
    <h2><i className="icon search"/></h2>
  </SearchIcon>
);
