
const FieldConfigs = [{
  name: 'email',
  label: 'Email',
  placeholder: 'Enter email',
}, {
  name: 'firstName',
  label: 'First Name',
  placeholder: 'Enter first name',
  autoFocus: true,
  colSpan: 6,
}, {
  name: 'lastName',
  label: 'Last Name',
  placeholder: 'Enter last name',
  colSpan: 6,
}, {
  name: 'newPassword',
  label: 'Password',
  placeholder: 'Enter password',
  colSpan: 6,
  type: 'password',
}, {
  name: 'rePassword',
  label: 'Re Password',
  placeholder: 'Enter password',
  colSpan: 6,
  type: 'password',
}, {
  name: 'telephone',
  label: 'Telephone',
  placeholder: 'Enter telephone number',
  colSpan: 6,
}]

const VerificationFieldConfigs = [{
  name: 'emailPassCode',
  label: 'One-Time Password (OTP)',
  placeholder: 'Enter OTP',
}];




export { FieldConfigs, VerificationFieldConfigs }
