import React, { useLayoutEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { createUseStyles } from "react-jss";
import classnames from "classnames";


const useStyles = createUseStyles({
  containerBox: {
    animation: "animate-grow-container 2000ms forwards"
  }
});

const NotFoundPage = () => {
  const classes = useStyles();
  const [showNow, setShowNow] = useState(false);
  useLayoutEffect(() => {
    const _timer = setTimeout(() => {
      setShowNow(true);
    }, 2000);
    return () => clearTimeout(_timer);
  }, []);

  if (!showNow) {
    return null;
  }

  return (
    <div className={classnames("container-fluid top-space", classes.containerBox)}>
      <Row>
        <Col lg={12} className="error-page">
          <span role="img" aria-label="hello"
                className="randomQuote-emoji">🤕</span><br />
          <h3>Oops.. we're sorry this page doesn't seem to exist</h3>
          <p>Please contact support.</p>
        </Col>
      </Row>
    </div>
  );
};


export default NotFoundPage;


