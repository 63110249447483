// eslint-disable-next-line
import React  from 'react';
import './tiny-spinner.component.css';

function TinySpinner() {
  return (
    <div className="tiny-spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};

export default TinySpinner;
