import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_COMPANY_OVERVIEW_SITES_DETAILS_QUERY
} from "../../../constants/queries/dashboard";
import HoveredSiteContext from "../../../components/HoveredSiteContext";
import CompanyMap from "../../../components/map/CompanyMap";
import CompanySiteTable from "../../../components/tables/SitesTable";
import { useDebouncedCallback } from "../../../hooks";
import CompanySiteMapOverviewLoader
  from "../../../components/loaders/CompanySiteMapOverviewLoader";


const CompanyOverviewSitesMapTable = React.memo(({ companyId }) => {

  const [hoveredSite, setHoveredSite] = useState(null);

  const [getCompanyOverviewSiteQuery, {
    data: companyOverviewSiteData,
    // error,
    loading
  }] = useLazyQuery(GET_COMPANY_OVERVIEW_SITES_DETAILS_QUERY, {});

  useEffect(() => {
    if (companyId) {
      getCompanyOverviewSiteQuery({
        variables: {
          id: companyId
        }
      });
    }
    // eslint-disable-next-line
  }, [companyId]);

  const onSetHoveredSite = useDebouncedCallback((params) => {
    setHoveredSite(params);
  }, 400, []);


  if (loading && !companyOverviewSiteData?.company){
    return <CompanySiteMapOverviewLoader />
  }

  return (
    <div>
      {companyOverviewSiteData?.company?.sites && (
        <HoveredSiteContext.Provider value={hoveredSite}>
          <CompanyMap companyId={companyId}
                      sites={companyOverviewSiteData.company.sites.nodes} />
          <CompanySiteTable companyId={companyId}
                            sites={companyOverviewSiteData.company.sites.nodes}
                            onRowHover={onSetHoveredSite} />
        </HoveredSiteContext.Provider>
      )}
    </div>
  );
});


export default CompanyOverviewSitesMapTable;
