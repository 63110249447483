import 'rc-tree/assets/index.css';
import React, {useCallback, useEffect, useReducer, useRef} from 'react'
import Tree from 'rc-tree';
import TerrainIcon from '@mui/icons-material/Terrain';
import {createUseStyles} from 'react-jss'
import useProgressiveDataLoader from '../../hooks/useProgressiveDataLoader'
import {
  GET_SITES_LOOKUP_QUERY,
} from '../../constants/queries/lookup'
import { FormGroup, Input, Label } from "reactstrap";
import CircularProgress from '@mui/material/CircularProgress'
import { orderBy } from 'lodash'

const useStyles = createUseStyles({
  iconContainer: {
    marginTop: '-3px',
  },
  selectAllInput:{
    marginTop: '.1rem'
  }
})

// eslint-disable-next-line import/prefer-default-export
function buildTree(allSites, classes) {
  const _treeData = [];
  const _allKeys = [];
  for (const site of allSites) {
    const _siteKey = site.id;
    const node = {
      key: _siteKey,
      title: site.name,
      type: 'SITE',
      children: [],
      checkable: true,
      selectable: false,
      isLeaf: true,
      // isChecked: true,
      icon: () => (
        <div className={classes.iconContainer}><TerrainIcon fontSize='small'/>
        </div>),
    };
    _allKeys.push(_siteKey);
    _treeData.push(node);
  }
  return {_treeData, _allKeys};
}


function reducer(currentState, newState) {
  return {...currentState, ...newState}
}

function useSiteTreeSelector({companyId, name, siteId, setFieldValue, classes}) {
  const treeRef = useRef();

  const {fetchData, data, allDataLoaded} = useProgressiveDataLoader({
    query: GET_SITES_LOOKUP_QUERY, isImmediate: false
  });

  const [{treeData, allSites, autoExpandParent, expandedKeys, checkedKeys}, setState] = useReducer(reducer, {
    treeData: [],
    allSites: [],
    autoExpandParent: true,
    expandedKeys: undefined,
    checkedKeys: undefined,
  });

  useEffect(() => {
    if (companyId) {
      fetchData({
        variables: {
          page: 1,
          companyId,
        }
      });
    }
  }, [companyId, fetchData])


  useEffect(() => {
    if (data) {
      setState({
        allSites: orderBy([...data], 'name')
      })
    }
  }, [data]);

  const selectByKeys = useCallback((_checkedKeys) => {
    const siteIds = [];
    for (const _checkedKey of _checkedKeys) {
      siteIds.push(_checkedKey);
    }
    setFieldValue(name ?? 'siteId', siteIds);
    // eslint-disable-next-line
  }, [setFieldValue, name]);

  useEffect(() => {
    if (allSites) {
      const {_treeData, _allKeys} = buildTree(allSites, classes);
      setState({
        treeData: _treeData,
        expandedKeys: _allKeys,
      });
    }
    // eslint-disable-next-line
  }, [allSites, selectByKeys]);


  useEffect(() => {
    if (treeData && treeData.length > 0) {
      const treemap = new Map();
      treeData.forEach((item=>{
        treemap.set(item.key, true);
      }));
      const _checkedKeys = [];
      if (siteId && Array.isArray(siteId)) {
        for (const _id of siteId) {
          if (treemap.has(_id)){
            _checkedKeys.push(_id);
          }
        }
      }
      setState({
        checkedKeys: _checkedKeys
      })
    }
  }, [siteId, treeData]);

  const onCheck = useCallback((_checkedKeys) => {
    selectByKeys(_checkedKeys);
    // eslint-disable-next-line
  }, [treeData, selectByKeys]);

  const onExpand = useCallback((_expandedKeys) => {
    setState({
      expandedKeys: _expandedKeys,
    })
    // eslint-disable-next-line
  }, []);

  const onSelectDeselectAll = useCallback((e)=>{
    if (e.target.checked === true){
      if (treeData && treeData.length > 0) {
        const treemap = new Map();
        treeData.forEach((item=>{
          treemap.set(item.key, true);
        }));
        const _checkedKeys = [...treemap.keys()];
        setState({
          checkedKeys: _checkedKeys
        })
        selectByKeys(_checkedKeys);
      }
    } else {
      setState({
        checkedKeys: []
      });
      selectByKeys([])
    }
  },[treeData, selectByKeys]);

  return {
    allDataLoaded,
    treeRef,
    treeData,
    expandedKeys,
    checkedKeys,
    onCheck,
    onExpand,
    autoExpandParent,
    onSelectDeselectAll
  };

}

const SitesTreeSelector = React.memo(function ({name, companyId, siteId, setFieldValue, height}) {
  const classes = useStyles();
  const {treeRef, treeData, allDataLoaded, expandedKeys, checkedKeys, onCheck, onExpand, onSelectDeselectAll} = useSiteTreeSelector({
    companyId,
    siteId,
    setFieldValue,
    classes,
    name,
  });

  if (!allDataLoaded) {
    return <div>
      <CircularProgress size={20} />
    </div>
  }
  return (<div>
    {treeData && treeData.length > 0 && (
      <FormGroup check>
        <Label check>
          <Input onChange={onSelectDeselectAll} type="checkbox" className={classes.selectAllInput} />
          Select/De-Select all
        </Label>
      </FormGroup>
    )}
    <Tree
      ref={treeRef}
      checkable
      multiple
      defaultCheckedAll
      expandedKeys={expandedKeys}
      checkedKeys={checkedKeys}
      treeData={treeData}
      showIcon
      onCheck={onCheck}
      onExpand={onExpand}
      height={height ?? 400}
    />
  </div>);
});


export default SitesTreeSelector;
