import React, { useCallback, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import {  isNumber, cloneDeep, isFunction } from 'lodash'
import { useLazyQuery } from "@apollo/client";
import Loader from "../../../components/loaders/Loader";
import CardLoader from "../../../components/loaders/CardLoader";
import {
  GET_INCIDENTS_PERFORMANCE_QUERY,
  GET_COMPANY_PERFORMANCE_QUERY,
  GET_COMPANY_OVERVIEW_QUERY
} from "../../../constants/queries/dashboard";
import useTelemetryKpiSettingsLookupData
  from "../../../hooks/useTelemetryKpiSettingsLookupData";
import * as performanceHelper from "../../../helpers/performanceHelper";
import RadialChart from "../../../components/charts/RadialChart";
import TinyReportDateSelector from "../../../components/TinyReportDateSelector";
import Doughnut from "../../../components/charts/DoughnutChart";
import { useDebouncedEffect } from "../../../hooks";
import {
  humanizeDurationAsLong
} from "../../../helpers/commonHelper";
import { createUseStyles } from "react-jss";
import useIncidentAckTimeKpiSettingsLookupData
  from "../../../hooks/useIncidentAckTimeKpiSettingsLookupData";
import CompanyOverviewSitesMapTable from "./CompanyOverviewSitesMapTable";
import commonReducer from '../../../common/commonReducer';

const colorNames = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];



function useCompanyOverview({ companyId }) {

  const [getCompanyOverviewQuery, {
    data: companyOverviewData,
    loading,
    stopPolling: stopCompanyOverviewPooling
  }] = useLazyQuery(GET_COMPANY_OVERVIEW_QUERY, {
    pollInterval: 30000,
  });

  const [getCompanyDevicePerformanceQuery, {
    data: companyDevicePerformances,
    loading: companyDevicePerformancesLoading,
    stopPolling: stopCompanyPerformancePooling
  }] = useLazyQuery(GET_COMPANY_PERFORMANCE_QUERY, {
    pollInterval: 30000,
  });

  const { kpiSettingsLookupData } = useTelemetryKpiSettingsLookupData();
  const { kpiSettingsLookupData: incidentAckKpiSettingsLookupData } = useIncidentAckTimeKpiSettingsLookupData();

  const [getIncidentPerformanceStatsQuery, {
    data: incidentPerformanceStatsData,
    stopPolling: stopIncidentPerformancePooling
  }] = useLazyQuery(GET_INCIDENTS_PERFORMANCE_QUERY, {
    pollInterval: 30000,
  });

  const [{
    avgPerformanceStatus,
    incidentsStats,
    isDateSelectorOpened,
    selectedReportDate,
    isIncidentSelectorOpened,
    selectedIncidentDate,
    averageDevicePerformance,
    optimalValuesChartData
  }, setState] = useReducer(commonReducer, {
    averageDevicePerformance: undefined,
    optimalValuesChartData: [],
    avgPerformanceStatus: undefined,
    isDateSelectorOpened: false,
    selectedReportDate: {
      label: "Last 24 hours",
      name: "now-24h"
    },
    isIncidentSelectorOpened: false,
    selectedIncidentDate: {
      label: "Last 24 hours",
      name: "now-24h"
    },
    incidentsStats: null
  });

 useEffect(()=>{
    if (isFunction(stopCompanyOverviewPooling)){
      return ()=>{
        if (isFunction(stopCompanyOverviewPooling)){
          stopCompanyOverviewPooling();
        }
      }
    }
  }, [stopCompanyOverviewPooling])

  useEffect(()=>{
    if (isFunction(stopCompanyPerformancePooling)) {
      return () => {
        if (isFunction(stopCompanyPerformancePooling)) {
          stopCompanyPerformancePooling();
        }
      }
    }
  }, [stopCompanyPerformancePooling])


  useEffect(()=>{
    if (isFunction(stopIncidentPerformancePooling)) {
      return () => {
        if (isFunction(stopIncidentPerformancePooling)) {
          stopIncidentPerformancePooling();
        }
      }
    }
  }, [stopIncidentPerformancePooling])

  useDebouncedEffect(() => {
    if (companyId) {
      getCompanyOverviewQuery({
        variables: {
          id: companyId
        }
      });
    }
    // eslint-disable-next-line
  }, 10,[companyId]);

  useDebouncedEffect(() => {
    if (companyId && selectedIncidentDate && selectedIncidentDate.name) {
     getIncidentPerformanceStatsQuery({
        variables: {
          companyId: companyId,
          from: selectedIncidentDate.name,
          to: "now"
        }
      });
    }
  }, 50, [companyId, selectedIncidentDate, getIncidentPerformanceStatsQuery]);


  useDebouncedEffect(() => {
    if (companyId && selectedReportDate && selectedReportDate.name) {
      const dataVariables = {
        id: companyId,
        from: selectedReportDate.name,
        to: "now"
      };
      getCompanyDevicePerformanceQuery({
        variables: dataVariables
      });
    }
  }, 50, [companyId, selectedReportDate, getCompanyDevicePerformanceQuery]);


  useEffect(() => {
    if (incidentPerformanceStatsData && incidentPerformanceStatsData.incidentPerformance) {
      const _incidentPerformance = cloneDeep(incidentPerformanceStatsData.incidentPerformance);
      if (isNumber(_incidentPerformance.averageAcknowledgementTime)) {
        _incidentPerformance.averageAcknowledgementTimeStatus = performanceHelper.getIncidentAckTimePerformanceStatus(_incidentPerformance.averageAcknowledgementTime, incidentAckKpiSettingsLookupData);
      }
      setState({
        incidentsStats: _incidentPerformance
      });
    } else {
      setState({
        incidentsStats: undefined
      });
    }
  }, [incidentPerformanceStatsData, incidentAckKpiSettingsLookupData]);


  useEffect(() => {
    if (companyDevicePerformances && companyDevicePerformances.company && companyDevicePerformances.company.devicePerformances) {
      const _tempDevicePerformances = [...companyDevicePerformances.company.devicePerformances];
      const _devicePerformances = performanceHelper.mergeGroupedPerformances(_tempDevicePerformances, "siteName");
      const avgPerformance = performanceHelper.calculateAvgPerformance(_tempDevicePerformances);
      const _avgPerformanceStatus = performanceHelper.getPerformanceStatus(avgPerformance, kpiSettingsLookupData);
      const _optimalValuesChartData = _devicePerformances.map(item => {
        return {
          category: item.siteName,
          value: item.optimalPercentage,
          full: 100
        };
      });
      setState({
        devicePerformances: _devicePerformances,
        averageDevicePerformance: avgPerformance,
        optimalValuesChartData: _optimalValuesChartData,
        avgPerformanceStatus: _avgPerformanceStatus
      });
    }
  }, [companyDevicePerformances, kpiSettingsLookupData]);


  const onReportDateSelect = useCallback((menuItem) => {
    if (menuItem) {
      setState({
        selectedReportDate: { ...menuItem }
      });
    }
  }, []);

  const onIncidentDateSelect = useCallback((menuItem) => {
    if (menuItem) {
      setState({
        selectedIncidentDate: { ...menuItem }
      });
    }
  }, []);

  return {
    companyOverviewData,
    kpiSettingsLookupData,
    companyDevicePerformances,
    companyDevicePerformancesLoading,
    avgPerformanceStatus,
    incidentsStats,
    isDateSelectorOpened,
    selectedReportDate,
    isIncidentSelectorOpened,
    selectedIncidentDate,
    averageDevicePerformance,
    optimalValuesChartData,
    onReportDateSelect,
    onIncidentDateSelect,
    setState,
    loading
  };
}


const useStyles = createUseStyles({
  avgDurationContainer: {
    position: "absolute",
    bottom: "120px",
    left: "15px"
  },
  avgAckTimeContainer: {
    position: "absolute",
    bottom: "20px",
    left: "15px"
  },
  statsText: {
    color: "#64686b"
  },
  statsTextValue: {
    fontWeight: "bold"
  }
});

const CompanyOverview = React.memo(function CompanyOverview({
                                                              companyId
                                                            }) {

  const classes = useStyles();
  const {
    companyOverviewData,
    setState,
    companyDevicePerformancesLoading,
    avgPerformanceStatus,
    incidentsStats,
    isDateSelectorOpened,
    selectedReportDate,
    isIncidentSelectorOpened,
    selectedIncidentDate,
    averageDevicePerformance,
    optimalValuesChartData,
    onReportDateSelect,
    onIncidentDateSelect,
    loading
  } = useCompanyOverview({ companyId });

  if (!companyId) {
    return null;
  }


  return (
    <React.Fragment>
      {loading && <Loader />}
      {companyOverviewData?.company ? (
      <div className="">
        <div className="container-fluid top-space">
          <Row className="container-content">
            <Col lg={12}>
              <Card className="stats-card card-new">
                <div className="card-new-header">
                  {companyOverviewData?.company?.name}
                </div>
                <div className="card-new-content">
                  <Row>
                    <Col
                      className="stats-improve value-text common-border-right">
                      <Link to={`/company/${companyId}/assets`}>
                        <Row>
                          <Col>
                            <h5>ASSETS</h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={5}>
                            <h2>{loading ? (
                              <CardLoader />) : companyOverviewData?.company?.assets?.count}</h2>
                          </Col>
                          <Col lg={7} className="stats-text">
                            {/*<span><i className="icon times circle"/>X properties offline</span>*/}
                          </Col>
                        </Row>
                      </Link>
                    </Col>
                    <Col
                      className="stats-improve value-text common-border-right">
                      <Link to={`/company/${companyId}/devices`}>
                        <Row>
                          <Col>
                            <h5>DEVICES</h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={5}>
                            <h2>{loading ? (
                              <CardLoader />) : companyOverviewData?.company?.stats?.devices?.totalCount}</h2>
                          </Col>
                        </Row>
                      </Link>
                    </Col>
                    <Col
                      className="stats-improve value-text common-border-right">
                      <Link to={`/company/${companyId}/incidents`}>
                        <Row>
                          <Col>
                            <h5>INCIDENTS</h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <h2>{companyOverviewData?.company?.stats?.incidents?.open > 0 ? companyOverviewData?.company?.stats?.incidents?.open :
                              <i className="icon check green" />}</h2>
                          </Col>
                          <Col lg={8} className="stats-text">
                            <span><i
                              className="icon eye" /> {loading ? (
                              <CardLoader />) : companyOverviewData?.company?.stats?.incidents?.incomplete} incomplete</span>
                          </Col>
                        </Row>
                      </Link>
                    </Col>
                    <Col className="stats-improve value-text">
                      <Row>
                        <Col>
                          <h5>PERFORMANCE</h5>
                        </Col>
                      </Row>
                      {companyDevicePerformancesLoading ? (<CardLoader />) : (
                        <React.Fragment>
                          {isFinite(averageDevicePerformance) && (
                            <Row>
                              <Col lg={5}>
                                <h2>{averageDevicePerformance} %</h2>
                              </Col>
                              <Col lg={7} className="stats-text">
                                     <span><i
                                       className={avgPerformanceStatus.icon} />{avgPerformanceStatus.status}
                                    </span>
                              </Col>
                            </Row>
                          )}
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </div>
                <Col className="card-slim-header" />
                <div className="card-new-content2">
                  <Row>
                    <Col lg={6} className="common-border-right">
                      {companyOverviewData?.company ? (
                        <CompanyOverviewSitesMapTable
                          companyId={companyId} />) : null}
                    </Col>
                    <Col>
                      <Row>
                        <Col
                          className="stats-improve value-text common-border-right"
                          lg={6}>
                          <Row>
                            <Col>
                              <h5 className="inline-block">INCIDENT
                                PERFORMANCE</h5>
                              <div className="float-right">
                                <TinyReportDateSelector
                                  onSelect={onIncidentDateSelect}
                                  toggle={() => {
                                    setState({
                                      isIncidentSelectorOpened: !isIncidentSelectorOpened
                                    });
                                  }} isOpen={isIncidentSelectorOpened} />
                              </div>
                            </Col>
                          </Row>
                          {incidentsStats ? (
                            <React.Fragment>
                              <Row>
                                <Col xs={12}>
                                  <h2>{incidentsStats && incidentsStats.count} </h2>
                                  <div>{selectedIncidentDate && selectedIncidentDate.label}</div>
                                </Col>
                              </Row>
                              <Row className="position-relative">
                                <Col>
                                  <div
                                    className="margin-left-6 margin-top-10">
                                    {incidentsStats && incidentsStats.stats.map((typeStats, i) =>
                                      <p key={`incident-chart-type-${i}`}><i
                                        className={`icon circle ${colorNames[i] || "color-a"}`} />{typeStats.count} {typeStats.type}
                                      </p>)}
                                  </div>
                                  {incidentsStats?.avgDuration ? (
                                    <div
                                      className={classes.avgDurationContainer}>
                                      <h5
                                        className={classes.statsText}>Average
                                        duration</h5>
                                      <h5
                                        className={classes.statsTextValue}> {humanizeDurationAsLong(incidentsStats?.avgDuration, "s")}</h5>
                                    </div>
                                  ) : null}
                                  {incidentsStats?.averageAcknowledgementTime ? (
                                    <div
                                      className={classes.avgAckTimeContainer}>
                                      <h5
                                        className={classes.statsText}>Average
                                        acknowledgement</h5>
                                      <h5
                                        className={classes.statsTextValue}> {humanizeDurationAsLong(incidentsStats?.averageAcknowledgementTime, "s")}</h5>
                                      {incidentsStats?.averageAcknowledgementTimeStatus ? (
                                        <span><i
                                          className={incidentsStats?.averageAcknowledgementTimeStatus?.icon} />{incidentsStats?.averageAcknowledgementTimeStatus?.status}  </span>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </Col>
                                <Col>
                                  {incidentsStats?.stats.length === 0 ? (
                                    <h1><i
                                      className="icon check circle green" />
                                    </h1>) : null}
                                  {incidentsStats?.stats && incidentsStats?.stats.length > 0 ? (
                                    <Doughnut
                                      data={incidentsStats.stats} />) : null}
                                </Col>
                              </Row>
                            </React.Fragment>
                          ) : <CardLoader />}
                        </Col>
                        <Col className="stats-improve value-text" lg={6}>
                          <Row>
                            <Col>
                              <div>
                                <h5 className="inline-block">SITE
                                  PERFORMANCE</h5>
                                <div className="float-right">
                                  <TinyReportDateSelector
                                    onSelect={onReportDateSelect}
                                    toggle={() => {
                                      setState({
                                        isDateSelectorOpened: !isDateSelectorOpened
                                      });
                                    }} isOpen={isDateSelectorOpened} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {companyDevicePerformancesLoading ? (
                            <CardLoader />) : (
                            <React.Fragment>
                              {isFinite(averageDevicePerformance) ? (
                                <React.Fragment>
                                  <Row>
                                    <Col xs={12}>
                                      <h2>{averageDevicePerformance}%</h2>
                                    </Col>
                                    <Col>
                                      <div>{selectedReportDate && selectedReportDate.label}</div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {optimalValuesChartData ?
                                      <RadialChart
                                        series={optimalValuesChartData} /> :
                                      <CardLoader />}
                                  </Row>
                                </React.Fragment>
                              ) : null}
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                      <br />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ): null}
    </React.Fragment>);

});

export default CompanyOverview;
