import {useContext, useEffect, useReducer} from 'react'
import {useLazyQuery} from "@apollo/client";
import GlobalContext from "../../../providers/GlobalContext";
import {GET_USER_PROFILE} from "../../../constants/queries/dashboard";

function reducer(currentState, newState) {
  return {...currentState, ...newState}
}

function useHandleInitialUpdateProfileData() {

  const { loggedInUser} = useContext(GlobalContext);

  const [fetchData, { data: userData }] = useLazyQuery(GET_USER_PROFILE)

  const [{initialValues, initialised}, setState] = useReducer(reducer, {
    initialValues: {},
    initialised: false,
  })

  useEffect(() => {
    if (userData && userData.user) {
      setState({
        initialValues: { ...userData.user },
        initialised: true,
      })
    }
  }, [userData])

  useEffect(() => {
    if (loggedInUser) {
      // eslint-disable-next-line camelcase
      fetchData({ variables: { id: loggedInUser.id } , fetchPolicy: 'network-only' })
    }
    // eslint-disable-next-line
  }, [loggedInUser])


  return {initialValues, initialised}

}

export default useHandleInitialUpdateProfileData
