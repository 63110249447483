import React from 'react'
import {
  Row,
  Col,
  Button,
  Alert,
  ModalHeader,
  ModalBody, Modal, ModalFooter, Container,
} from 'reactstrap'
import { Formik } from 'formik'
import DynamicFormikField from '../../../components/forms/DynamicFormikField'
import TinySpinner from '../../../components/TinySpinner'
import { FieldConfigs } from './ChangePasswordFields'
import useChangePasswordForm from './useChangePasswordForm'

const ChangePassword = React.memo(function ChangePassword({
                                                            isOpen,
                                                            setIsOpen,
                                                            ...props
                                                          }) {
  const {
    error,
    formikOptions,
    success,
    successMessage,
    errorMessage,
  } = useChangePasswordForm({
    ...props,
    setIsOpen,
  })
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formikOptions

  return (
    <Modal isOpen={isOpen}>
      <Formik {...formikOptions}>
        <form onSubmit={handleSubmit}>
          <ModalHeader className='border-bottom-0'>Change
            Password</ModalHeader>
          <ModalBody>
            <div className='container-content'>
              <Row className=''>
                <Col>
                  {error ? (
                    <Alert color='danger' isOpen={!!error}>
                      <div>{errorMessage}</div>
                    </Alert>
                  ) : (
                    success && (
                      <Alert color='success'>
                        <div>{successMessage}</div>
                      </Alert>
                    )
                  )}
                  <Row>
                    {FieldConfigs.map((fieldConfig, configIndex) => (
                      <Col md={fieldConfig.colSpan || 12}
                           key={`form-field-key-${configIndex}`}>
                        <DynamicFormikField
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          fieldConfig={fieldConfig} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className='border-top-0'>
            <Container>
              <Row>
                <Col md={6}>
                  <Button color='primary' className='w-100'
                          type='submit' disabled={isSubmitting}>
                    {!isSubmitting && <span>Submit</span>}
                    {isSubmitting && (
                      <span>Submitting<TinySpinner /></span>
                    )}
                  </Button>
                </Col>
                <Col md={6}>
                  <Button className='w-100' color='secondary' onClick={() => {
                    setIsOpen(false)
                  }}>Close</Button>
                </Col>
              </Row>
            </Container>
          </ModalFooter>

        </form>
      </Formik>
    </Modal>
  )
})


export default ChangePassword
