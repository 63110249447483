import { isNumber } from "lodash";

function calculateCumulativeCompanyStatsData({ loggedInUser, companyStatsData }) {
  let _systemCheckCountData = {
    total: 0,
    incomplete: 0
  };
  let incidentCountData = 0;
  if (loggedInUser && companyStatsData?.company?.stats) {
    if (loggedInUser && ["GLOBAL_ADMIN", "COMPANY_ADMIN", "COMPANY_SUPERVISOR", "SITE_ADMIN"].includes(loggedInUser.role)) {
      if (companyStatsData?.company?.stats?.systemCheck?.siteStats?.weekly) {
        if (isNumber(companyStatsData?.company?.stats?.systemCheck?.siteStats?.weekly?.incomplete)) {
          _systemCheckCountData.incomplete += companyStatsData?.company?.stats?.systemCheck?.siteStats?.weekly?.incomplete;
        }
        if (isNumber(companyStatsData?.company?.stats?.systemCheck?.siteStats?.weekly?.total)) {
          _systemCheckCountData.total += companyStatsData?.company?.stats?.systemCheck?.siteStats?.weekly?.total;
        }
      }
    }

    if (companyStatsData?.company?.stats?.systemCheck?.zoneStats?.weekly) {
      if (isNumber(companyStatsData?.company?.stats?.systemCheck?.zoneStats?.weekly?.incomplete)) {
        _systemCheckCountData.incomplete += companyStatsData?.company?.stats?.systemCheck?.zoneStats?.weekly?.incomplete;
      }
      if (isNumber(companyStatsData?.company?.stats?.systemCheck?.zoneStats?.weekly?.total)) {
        _systemCheckCountData.total += companyStatsData?.company?.stats?.systemCheck?.zoneStats?.weekly?.total;
      }
    }

    if (companyStatsData?.company?.stats?.systemCheck?.siteStats?.intraday) {
      if (isNumber(companyStatsData?.company?.stats?.systemCheck?.siteStats?.intraday?.incomplete)) {
        _systemCheckCountData.incomplete += companyStatsData?.company?.stats?.systemCheck?.siteStats?.intraday?.incomplete;
      }
      if (isNumber(companyStatsData?.company?.stats?.systemCheck?.intraday?.siteStats?.total)) {
        _systemCheckCountData.total += companyStatsData?.company?.stats?.systemCheck?.siteStats?.intraday?.total;
      }
    }
    incidentCountData = companyStatsData?.company?.stats?.incidents ?? undefined;
  }

  return {
    incidentCountData,
    systemCheckCountData: _systemCheckCountData
  };
}


export {
  calculateCumulativeCompanyStatsData
}
