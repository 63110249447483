import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import {pick} from 'lodash';
import { ValidationSchema } from './ChangePasswordFields'
import {
  AUTHENTICATE_CHANGE_PASSWORD_MUTATION,
} from '../../../constants/mutations/auth'
import GlobalContext from '../../../providers/GlobalContext'
import { toast } from 'react-toastify'


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useChangePasswordForm({setIsOpen}) {

  const client = useApolloClient();
  const { loggedInUser} = useContext(GlobalContext);
  const timerRef = useRef(null);

  const [{ success, successMessage, error, errorMessage }, setState] = useReducer(reducer, {
    success: false,
  });

  useLayoutEffect(()=>{
    if (loggedInUser){
      // alert('loggedInUser here')
     // clearSession();
    }
  },[loggedInUser])

 const [changePasswordRequest] = useMutation(AUTHENTICATE_CHANGE_PASSWORD_MUTATION);

  const processChangePassword = useCallback(async function processChangePassword(setSubmitting, finalData) {
    setSubmitting(true)
    const result = await changePasswordRequest({ variables: finalData })
    setSubmitting(false)
    if (result && result.data && result.data.changePassword) {
      if (result.data.changePassword.success === true) {
        /*setState({
          error: false,
          errorMessage: undefined,
          success: true,
          successMessage: 'Your password has been reset successfully',
        });*/
        await client.clearStore();
        toast(`Your password has been reset successfully.`, {
          type: toast.TYPE.SUCCESS
        })
        setIsOpen(false);
      } else {
        setState({
          error: true,
          errorMessage: Object.values(result.data)[0] && Object.values(result.data)[0].message ? Object.values(result.data)[0].message : 'Failed Request',
          success: false,
          successMessage: undefined,
        })
      }
    }
    // eslint-disable-next-line
  }, [changePasswordRequest])

  const formikOptions = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    initialValues: {
      oldPassword: '',
      rePassword: '',
      newPassword: ''
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      const finalData = pick(values, 'oldPassword', 'newPassword');
      finalData.newPassword = btoa(finalData.newPassword);
      finalData.oldPassword = btoa(finalData.oldPassword);
      finalData.id = loggedInUser.id;

      setState({
        error: false,
        errorMessage: undefined,
        success: false,
        successMessage: undefined,
      })
      try {
        await processChangePassword(setSubmitting, finalData);
      } catch (err) {
        console.log(err)
        if (err && err.message) {
          setState({
            error: true,
            errorMessage: err.message,
          })
        }
      }
    },
  });


  useEffect(()=>{
    return ()=>{
      if(timerRef && timerRef.current){
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }
    // eslint-disable-next-line
  }, [])

  return { success, successMessage, error, errorMessage, formikOptions }

}


export default useChangePasswordForm
