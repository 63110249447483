import React, {useMemo, useState} from 'react';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from 'reactstrap'
import {Field} from 'formik'
import {isUndefined} from 'lodash'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames';
import {v4} from 'uuid'

const useStyles = createUseStyles({
  checkBoxContainer: {
    marginTop: '2rem',
  },
  checkBoxContainerInline: {
    marginTop: '.2rem',
  },
  checkBoxLabel: {
    marginTop: '0.3rem',
  },
  checkBoxInput: {
    marginTop: '0.1rem',
  },
  passWrapper: {
    position: 'relative',
    display: 'flex',
  },
  eyeIcon: {
    position: 'absolute',
    top: "27%",
    right: "5%",
    '&:hover': {
      color: "#000000",
      cursor: 'pointer'
    }
  }
})

const DynamicFormikField = React.memo(({
                                         fieldConfig,
                                         handleChange,
                                         handleBlur,
                                         values,
                                         touched,
                                         errors,
                                         disabled,
                                         setFieldValue,
                                       }) => {
  const {
    name,
    label,
    selector,
    placeholder, autoFocus, type = 'text', options = [],
    rows,
    step,
    inline
  } = useMemo(() => fieldConfig, [fieldConfig]);

  const classes = useStyles();
  const [isPasswordMode, setPasswordMode] = useState(true);

  if (selector && typeof selector === 'object') {
    const elem = React.createElement(selector, {
      name,
      onChange: handleChange,
      onBlur: handleBlur,
      autoFocus,
      setFieldValue,
      disabled: disabled,
      value: !isUndefined(values[name]) ? values[name] : '',
      invalid: !!(touched[name] && errors[name]),
    })

    if (!elem) {
      return null
    }

    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        {elem}
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
    )
  }

  if (type === 'select') {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input tag={Field} type={type} name={name} placeholder={placeholder}
               onChange={handleChange}
               onBlur={handleBlur}
               disabled={disabled}
               autoFocus={autoFocus}
               value={!isUndefined(values[name]) ? values[name] : ''}
               invalid={!!(touched[name] && errors[name])}
        >
          {options && options.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </Input>
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
    )
  }

  if (type === 'checkbox') {
    const id = 'checkbox-' + v4();
    return (
      <FormGroup check
                 className={!inline ? classes.checkBoxContainer : classes.checkBoxContainerInline}>
        <Label check className={classes.checkBoxLabel}>
          <Input tag={Field} type={type}
                 className={classes.checkBoxInput}
                 name={name}
                 placeholder={placeholder}
                 onChange={handleChange}
                 onBlur={handleBlur}
                 disabled={disabled}
                 autoFocus={autoFocus}
                 checked={!isUndefined(values[name]) ? values[name] : false}
                 invalid={!!(touched[name] && errors[name])}
          />
          {label} {placeholder ? (<i id={id}
                                     className="icon question circle"/>) : null}
        </Label>
        <FormFeedback>{errors[name]}</FormFeedback>
        {placeholder && (
          <UncontrolledTooltip placement="right" target={id}>
            {placeholder}
          </UncontrolledTooltip>
        )}
      </FormGroup>
    )
  }

  if (type === 'textarea') {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input type='textarea' name={name} placeholder={placeholder}
               onChange={handleChange}
               onBlur={handleBlur}
               disabled={disabled}
               autoFocus={autoFocus}
               rows={rows || 5}
               tag={Field}
               as="textarea"
               value={!isUndefined(values[name]) ? values[name] : ''}
               invalid={!!(touched[name] && errors[name])}
        />
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
    )
  }

  if (type === "password") {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <div className={classes.passWrapper}>
          <Input tag={Field} type={isPasswordMode ? "password" : "text"}
                 name={name} placeholder={placeholder}
                 onChange={handleChange}
                 onBlur={handleBlur}
                 disabled={disabled}
                 autoFocus={autoFocus}
                 step={step}
                 value={!isUndefined(values[name]) ? values[name] : ''}
                 invalid={!!(touched[name] && errors[name])}
          />
          <i onClick={() => setPasswordMode(prevState => {
            setPasswordMode(!prevState);
          })} className={classnames(classes.eyeIcon, "eye icon")}></i>
        </div>
        {touched[name] && errors[name] ? (
          <span className="error-message-block">{errors[name]}</span>
        ) : null}
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input tag={Field} type={type} name={name} placeholder={placeholder}
             onChange={handleChange}
             onBlur={handleBlur}
             disabled={disabled}
             autoFocus={autoFocus}
             step={step}
             value={!isUndefined(values[name]) ? values[name] : ''}
             invalid={!!(touched[name] && errors[name])}
      />
      <FormFeedback>{errors[name]}</FormFeedback>
    </FormGroup>
  )
});


export default DynamicFormikField
