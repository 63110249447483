import React from "react";
import ContentLoader from "react-content-loader";
import {fill} from 'lodash';

const rows = fill(Array(10), 1);
const defaultHeight = 400;
const rowHeight = 30;
const rowMargin = 5;

const CompanySiteMapOverviewLoader = (props) => {
  return (
    <div style={{ width: "100%", height: `${defaultHeight + (rows.length * (rowHeight + rowMargin) )}px` }}>
        <ContentLoader  style={{ width: "100%", height: "100%" }} {...props}>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height={defaultHeight} />
          {rows.map((_, index)=>(
            <rect key={`indx_${index}`} x="0" y={defaultHeight + 40 + (index * (rowHeight + rowMargin))} rx="5" ry="5" width="100%" height={rowHeight} />
          ))}
        </ContentLoader>
    </div>
  );
};

export default CompanySiteMapOverviewLoader;
