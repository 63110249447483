
function parseFileAsUrl(file){
  return  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload =  (e) => {
      const data = e.target.result;
      resolve(data);
    };
    // readAsBinaryString
    reader.readAsDataURL(file);
  });
}


function parseFileAsBinary(file){
  return  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload =  (e) => {
      const data = e.target.result;
      resolve(data);
    };
    // readAsBinaryString
    reader.readAsBinaryString(file);
  });
}

export default  {parseFileAsUrl, parseFileAsBinary}
