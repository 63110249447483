import React from 'react'
import { Input } from 'reactstrap'

const _values = [
  { name: 'RAW_TELEMETRY_CSV', label: 'RAW_TELEMETRY_CSV' },
  { name: 'ASSET_SUMMARY', label: 'ASSET-SUMMARY' },
  { name: 'ASSET_INCIDENT', label: 'ASSET-INCIDENT' },
  { name: 'INCIDENTS', label: 'INCIDENTS' },
  { name: 'ASSET_JOURNEY', label: 'ASSET-JOURNEY' },
  { name: 'ASSET_PERFORMANCE', label: 'ASSET-PERFORMANCE' },
  { name: 'ASSET_PERFORMANCE_HISTORY', label: 'ASSET_PERFORMANCE_HISTORY' },
  { name: 'ASSET_SUMMARY_PERFORMANCE_CSV', label: 'ASSET_SUMMARY_PERFORMANCE_CSV'},
  { name: 'SITE_SUMMARY', label: 'SITE_SUMMARY' },
  { name: 'SITE_SUMMARY_CSV', label: 'SITE_SUMMARY_CSV' },
  { name: 'SITE_PERFORMANCE', label: 'SITE-PERFORMANCE' },
  { name: 'SITE_PERFORMANCE_CSV', label: 'SITE-PERFORMANCE_CSV' },
  { name: 'SITE_COMPLIANCE_INTRADAY', label: 'SITE_COMPLIANCE_INTRADAY' },
  { name: 'SITE_COMPLIANCE_WEEKLY', label: 'SITE_COMPLIANCE_WEEKLY' },
  { name: 'ZONE_PERFORMANCE', label: 'ZONE-PERFORMANCE' },
  { name: 'ZONE_COMPLIANCE_WEEKLY', label: 'ZONE_COMPLIANCE_WEEKLY' },
  { name: 'COMPANY_INCIDENT_CSV', label: 'COMPANY_INCIDENT_CSV' },
  { name: 'COMPANY_PERFORMANCE', label: 'COMPANY-PERFORMANCE' }]

const ExportReportSelector = React.memo(({ setFieldValue, ...props }) => {

  return (
    <Input placeholder='Select'
           onChange={props.handleChange}
           onBlur={props.handleBlur}
           type='select' {...props}>
      <option value=''>Select</option>
      {_values.map((_val, index) => {
        return (
          <option key={index} value={_val.name}>
            {_val.label}
          </option>
        )
      })}
    </Input>)
})


export default ExportReportSelector
