import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { RenderRoutes } from './routes/routes'
import GlobalContextProvider from './providers/GlobalContextProvider'

// Themes
import './assets/scss/DefaultTheme.scss'


/**
 * Main app component
 */
class App extends Component {

  render() {
    return (<BrowserRouter><GlobalContextProvider><RenderRoutes />
    </GlobalContextProvider></BrowserRouter>)
  }

}
export default App;
