import React, { useContext, useMemo } from 'react'
import styled from "styled-components";

import HitList from "./HitList";
import SpotlightContext from "./SpotlightContext";

const Hits = styled.div`
  max-height: 0;
  min-height: 0;
  overflow: hidden;
  transition: all 0.3s;
  background-color: rgba(0, 20, 41, 0.97);

  ${props =>
    props.open &&
    `
    min-height: 375px;
    max-height: 400px;
    border-top: 1px solid #515253;
  `};
`;

 const HitsComponent  = React.memo(() => {
    const { flatHits } = useContext(SpotlightContext);

   const hasResults = useMemo(()=> flatHits?.length > 0,[flatHits]);

   if (!hasResults){
     return null;
   }

   return (
     <Hits open={hasResults}>
           <HitList />
     </Hits>
   );
});

export default HitsComponent;
