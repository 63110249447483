import { gql } from '@apollo/client';

export const ACKNOWLEDGE_INCIDENT_MUTATION = gql`
  mutation acknowledgeIncident($id: ID!) {
    acknowledgeIncident(input: { id: $id }) {
      success
      message
    }
  }
`;

export const BULK_ACKNOWLEDGE_INCIDENT_MUTATION = gql`
  mutation bulkAcknowledgeIncident($ids: [ID!]) {
    bulkAcknowledgeIncident(input: { ids: $ids }) {
      success
      message
    }
  }
`;

export const ACKNOWLEDGE_INCIDENT_BY_LINK_MUTATION = gql`
  mutation acknowledgeIncidentByLink($linkId: ID!) {
    acknowledgeIncidentByLink(input: { linkId: $linkId }) {
      success
      message
    }
  }
`;

export const ADD_INCIDENT_VIEWED_AUDIT_LOG_MUTATION = gql`
  mutation addIncidentViewedAuditLog($id: ID!) {
    addIncidentViewedAuditLog(input: { id: $id }) {
      success
      message
    }
  }
`;

export const ADD_INCIDENT_COMMENT_MUTATION = gql`
  mutation addIncidentComment(
    $id: ID!
    $comment: String!
    $tag: String
    $userMentions: [ID!]
    $rootCauseType: ID
    $correctiveActionType: ID
    $preventiveActionType: ID
  ) {
    addIncidentComment(
      input: {
        id: $id
        comment: $comment
        tag: $tag
        userMentions: $userMentions
        rootCauseType: $rootCauseType
        correctiveActionType: $correctiveActionType
        preventiveActionType: $preventiveActionType
      }
    ) {
      success
      message
    }
  }
`;

export const CREATE_CUSTOM_REPORT_MUTATION = gql`
  mutation createCustomReport(
    $reportType: String!
    $incidentIds: [ID!]
    $assetIds: [ID!]
    $siteIds: [ID!]
    $zoneIds: [ID!]
    $companyIds: [ID!]
    $from: Float!
    $to: Float!
  ) {
    createCustomReport(
      input: {
        reportType: $reportType
        incidentIds: $incidentIds
        assetIds: $assetIds
        siteIds: $siteIds
        zoneIds: $zoneIds
        companyIds: $companyIds
        from: $from
        to: $to
      }
    ) {
      success
      message
    }
  }
`;

export const ADD_COMPLIANCE_COMMENT_MUTATION = gql`
  mutation createComplianceComment(
    $id: ID!
    $comment: String!
    $userMentions: [ID!]
  ) {
    createComplianceComment(
      input: { id: $id, comment: $comment, userMentions: $userMentions }
    ) {
      success
      message
    }
  }
`;

export const ADD_KUBE_VISION_FOOTAGE_REQUEST_COMMENT_MUTATION = gql`
  mutation createKubeVisionFootageRequestComment(
    $id: ID!
    $comment: String!
    $userMentions: [ID!]
  ) {
    createKubeVisionFootageRequestComment(
      input: { id: $id, comment: $comment, userMentions: $userMentions }
    ) {
      success
      message
    }
  }
`;

export const SYSTEM_CHECK_COMPLETE_MUTATION = gql`
  mutation completeSystemCheck(
    $id: ID!
    $comment: String!
    $userMentions: [ID!]
  ) {
    completeSystemCheck(
      input: { id: $id, comment: $comment, userMentions: $userMentions }
    ) {
      success
      message
    }
  }
`;

export const END_USER_AGREEMENT_ACCEPTANCE_MUTATION = gql`
  mutation acceptEndUserAgreement {
    acceptEndUserAgreement {
      success
      message
    }
  }
`;

export const UPLOAD_ASSET_FILES_MUTATION = gql`
  mutation uploadAssetFile($id: ID!, $files: [Upload!]!) {
    uploadAssetFile(input: { id: $id, files: $files }) {
      success
      message
    }
  }
`;

export const DELETE_ASSET_FILE_MUTATION = gql`
  mutation deleteAssetFile($id: ID!, $fileId: ID!) {
    deleteAssetFile(input: { id: $id, fileId: $fileId }) {
      success
      message
    }
  }
`;

export const GET_DEVICE_KUBE_VISION_STREAM_MUTATION = gql`
  mutation getDeviceKubeVisionLiveStreamUri(
    $id: ID!
    $configId: ID!
    $quality: String!
  ) {
    getDeviceKubeVisionLiveStreamUri(
      input: { id: $id, configId: $configId, quality: $quality }
    ) {
      success
      message
      uri
      name
      configId
    }
  }
`;

export const CREATE_KUBE_VISION_FOOTAGE_REQUEST_MUTATION = gql`
  mutation createKubeVisionFootageRequest(
    $deviceId: ID!
    $assetId: ID!
    $monitorIds: [String!]!
    $startTime: Int!
    $endTime: Int!
  ) {
    createKubeVisionFootageRequest(
      input: {
        deviceId: $deviceId
        assetId: $assetId
        monitorIds: $monitorIds
        startTime: $startTime
        endTime: $endTime
      }
    ) {
      success
      message
      footageRequest {
        id
        requestUuid
      }
    }
  }
`;

export const GET_KUBE_VISION_FOOTAGE_REQUEST_URL = gql`
  mutation getKubeVisionFootageRequestUrl($id: ID!, $footageId: ID!) {
    getKubeVisionFootageRequestUrl(input: { id: $id, footageId: $footageId }) {
      url
    }
  }
`;

export const GET_KUBE_VISION_FOOTAGE_REQUEST_DOWNLOAD_ALL_VIDEO_FILE_URL = gql`
  mutation getKubeVisionFootageRequestAllVideoDownloadLinks($id: ID!) {
    getKubeVisionFootageRequestAllVideoDownloadLinks(input: { id: $id }) {
      url
      fileName
    }
  }
`;

export const GET_KUBE_VISION_FOOTAGE_REQUEST_DOWNLOAD_VIDEO_FILE_URL = gql`
  mutation getKubeVisionFootageRequestVideoDownloadLink(
    $id: ID!
    $footageId: ID!
  ) {
    getKubeVisionFootageRequestVideoDownloadLink(
      input: { id: $id, footageId: $footageId }
    ) {
      url
      fileName
    }
  }
`;

export const REQUEST_REVIEW_KUBE_VISION_FOOTAGE_REQUEST = gql`
  mutation requestReviewKubeVisionFootageRequest($id: ID!) {
    requestReviewKubeVisionFootageRequest(input: { id: $id }) {
      success
      message
    }
  }
`;

export const COMPLETE_REVIEW_KUBE_VISION_FOOTAGE_REQUEST = gql`
  mutation completeReviewKubeVisionFootageRequest($id: ID!) {
    completeReviewKubeVisionFootageRequest(input: { id: $id }) {
      success
      message
    }
  }
`;

export const ASSIGN_DRIVER_KUBE_VISION_FOOTAGE_REQUEST = gql`
  mutation assignDriverKubeVisionFootageRequest($id: ID!, $driverId: ID!) {
    assignDriverKubeVisionFootageRequest(
      input: { id: $id, driverId: $driverId }
    ) {
      success
      message
    }
  }
`;

export const REQUEST_DRIVER_COMMENT_KUBE_VISION_FOOTAGE_REQUEST = gql`
  mutation requestDriverCommentKubeVisionFootageRequest($id: ID!) {
    requestDriverCommentKubeVisionFootageRequest(input: { id: $id }) {
      success
      message
    }
  }
`;

export const ENABLE_DELETE_PROTECTION_KUBE_VISION_FOOTAGE_REQUEST = gql`
  mutation enableDeleteProtectionKubeVisionFootageRequest($id: ID!) {
    enableDeleteProtectionKubeVisionFootageRequest(input: { id: $id }) {
      success
      message
    }
  }
`;

export const DELETE_KUBE_VISION_FOOTAGE_REQUEST = gql`
  mutation deleteKubeVisionFootageRequest($id: ID!) {
    deleteKubeVisionFootageRequest(input: { id: $id }) {
      success
      message
    }
  }
`;
