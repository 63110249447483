import {isString} from "lodash";

const replaceDoubleQuoteInString = columnData =>
  typeof columnData === 'string' ? columnData.replace(/"/g, '""') : columnData;

function CsvDownLoader(columns, data, options, skipDownload= false) {

  const CSVHead =
    columns
      .reduce(
        (soFar, column) =>
          column.download !== false
            ? soFar + '"' + replaceDoubleQuoteInString(column.label) + '"' + options.downloadOptions.separator
            : soFar,
        '',
      )
      .slice(0, -1) + '\r\n';

  let _CSVBody = data
    .reduce(
      (soFar, row) =>
        soFar +
        '"' +
        columns
          .filter((field, index) => field.download !== false)
          .map(field => {
            let val='';
            if(field.getReportValue){
              val= field.getReportValue(row.data);
            } else if(field.filter){
              val= field.filter(row.data);
            } else if(field.render){
              let renderedVal = field.render(row.data);
              val= isString(renderedVal)? renderedVal: (row.data[field.name] ? row.data[field.name].toString(): '');
            } else if(field.name){
              val= row.data[field.name] ?? '';
            }
            return replaceDoubleQuoteInString(val);
          })
          .join('"' + options.downloadOptions.separator + '"') +
        '"\r\n',
      [],
    );

  const CSVBody = isString(_CSVBody) ? _CSVBody.trim(): '';

  const csv = `${CSVHead}${CSVBody}`;
  const blob = new Blob([csv], { type: 'text/csv' });

  if (skipDownload === true){
    const dataURI = `data:text/csv; charset=utf-8,${csv}`;
    const URL = window.URL || window.webkitURL;
    const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
    return {
      uri: downloadURI,
      name: options.downloadOptions.filename,
    };
  }
  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, options.downloadOptions.filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', options.downloadOptions.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default CsvDownLoader;
