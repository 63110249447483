import React, { useEffect } from 'react'
import * as classNames from 'classnames';
import { useTable, useSortBy, useFilters, useExpanded } from 'react-table'
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  subRows: {
    backgroundColor: 'rgb(247, 247, 247)'
  }
})


const Table =  React.memo(function Table({ columns, getSubRows, data = [], renderRowSubComponent, onRowHover = () => {} }) {

  const classes = useStyles();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    flatHeaders,
    toggleRowExpanded,
  } = useTable(
    {
      columns,
      data,
      getSubRows,
      autoResetExpanded: false
    },
    useFilters,
    useSortBy,
    useExpanded,
  )

  useEffect(() => {
    if (data && Array.isArray(data) && data.length === 1) {
      toggleRowExpanded(0, true)
    }
    // eslint-disable-next-line
  }, [data])

  return (
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-key-${ i}`}>
            {headerGroup.headers.map((column, colIndex) => (
              <th {...column.getHeaderProps()} key={`header-th-key-${ i}- ${colIndex}`}>
                <div {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="angle down icon table-sort"></i>
                          : <i className="angle up icon table-sort"></i>
                        : ''}
                    </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          const formattedRow = (
            <tr className={classNames(row.depth > 0 && classes.subRows)}  {...row.getRowProps()} key={`row-key-${ i}`} /*className={row.depth ? 'subRow' : ''}*/ onMouseOver={() => onRowHover(row.original)} onMouseOut={() => onRowHover(null)}>
              {row.cells.map((cell, cellIndex) => (
                <td {...cell.getCellProps()} key={`cell-key-${ i  }-${  cellIndex}`}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
          const expandedRow = renderRowSubComponent && row.isExpanded && (
            <tr key={`expanded-${i}`} className='expandedRow'>
              <td colSpan={flatHeaders.length}>
               {renderRowSubComponent && renderRowSubComponent({ row })}
              </td>
            </tr>
          )
          return [formattedRow, expandedRow]
        })}
        {(rows && rows.length === 0) && (
          <tr><td colSpan={10}><div>No data found</div></td></tr>
        )}
        </tbody>
      </table>
  )
});

// eslint-disable-next-line import/prefer-default-export
export  { Table };
