import * as Yup from 'yup'
import { passwordRegex } from '../../../constants/validationRegex'

const FieldConfigs = [{
  name: 'oldPassword',
  label: 'Current Password',
  autoFocus: true,
  placeholder: 'Enter your current password',
  type: 'password'
},{
  name: 'newPassword',
  label: 'Password',
  placeholder: 'Enter password',
  type: 'password'
}, {
  name: 'rePassword',
  label: 'Re Password',
  placeholder: 'Enter password',
  type: 'password'
}]


const ValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Enter your current password'),
    newPassword: Yup.string().required('Enter your new password').min(7, 'Must be at least 7 characters')
      .test({name: "newPassword",
        message: "Password should contain at least 1 lower case letter, 1 upper case letter, 1 number and 1 special character",
        test : (val)=> val && passwordRegex.test(val)}),
    rePassword: Yup.string().required('Enter your password again').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})


export  {FieldConfigs, ValidationSchema};
