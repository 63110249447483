import { gql } from '@apollo/client'


export const NOTIFICATION_PUSHED_SUBSCRIPTION = gql`
  subscription notification ($companyId: ID!) {
    notification (companyId: $companyId) {
      id
      event
      description
      time
      url
      siteId
      zoneId
      companyId
      isAcknowledged
    }
  }
`
