import axios from 'axios'
import { GQL_API_URL } from '../constants/env'
import axiosRetry from 'axios-retry'

axiosRetry(axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (error.message === 'Network Error') {
      return true
    }
    if (error?.message === 'Failed to fetch') {
      return true
    }
    if (/Knex: Timeout acquiring a connection/i.test(error?.message)) {
      return true
    }

    return (
      error.code !== 'ECONNABORTED' &&
      (!error.response || (error.response.status > 500 && error.response.status < 599))
    )
  },
})

const fetchData = async function(query, variables, token) {
  try {
    const data = {
      query: query,
      variables: variables,
    }
    const params = {}
    if (token) {
      params.headers = {
        'x-auth-token': token,
      }
    }
    const result = await axios.post(`${GQL_API_URL}?_dc=${Date.now()}`, data, params).catch(err => {
      throw err
    })
    if (result?.data?.errors) {
     // console.log('Error at graphql response!!')
      // console.dir(result.data.errors);
      return Promise.reject(new Error(result.data.errors[0].message))
    } else if (result && result.data && result.data.data) {
      return Promise.resolve(result.data.data)
    } else {
      //console.log('Unknown Error at graphqlClient.fetchData ', result)
      return Promise.reject(new Error('Graphql error'))
    }
  } catch (ex) {
    throw ex
  }
}


export default {
  fetchData,
}
