import 'rc-tree/assets/index.css'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import Tree from 'rc-tree'
import { sortBy, omit } from 'lodash'
import TerrainIcon from '@mui/icons-material/Terrain'
import RoomIcon from '@mui/icons-material/Room'
import { createUseStyles } from 'react-jss'
import useProgressiveDataLoader from '../../hooks/useProgressiveDataLoader'
import {
  GET_ZONES_SITES_LOOKUP_QUERY,
} from '../../constants/queries/lookup'
import { FormGroup, Input, Label } from "reactstrap";
import CircularProgress from '@mui/material/CircularProgress'


const useStyles = createUseStyles({
  iconContainer: {
    marginTop: '-3px',
  },
  selectAllInput:{
    marginTop: '.1rem'
  }
})

// eslint-disable-next-line import/prefer-default-export
function buildTree(allSites, classes) {
  const _treeData = []
  const _allKeys = []
  for (const site of allSites) {
    const _siteKey = JSON.stringify({
      siteId: site.id,
    })
    const node = {
      key: _siteKey,
      title: site.name,
      type: 'SITE',
      children: [],
      checkable: true,
      selectable: false,
      // isChecked: true,
      icon: () => (
        <div className={classes.iconContainer}><TerrainIcon fontSize='small'/>
        </div>),
    }
    _allKeys.push(_siteKey)
    if (site.zones && Array.isArray(site.zones)) {
      for (const zone of site.zones) {
        const _zoneKey = JSON.stringify({
          zoneId: zone.id,
        })
        node.children.push({
          key: _zoneKey,
          title: zone.name,
          type: 'ZONE',
          isLeaf: true,
          checkable: true,
          selectable: false,
          // isChecked: true,
          //  className: selectedFile && selectedFile.id && selectedFile.id === fileItem.id ?  'active-selected-node' : 'leaf-node',
          //  selectable: true,
          icon: () => (
            <div className={classes.iconContainer}><RoomIcon fontSize='small'/>
            </div>),
          siteId: site.id,
        })
        _allKeys.push(_zoneKey)
      }
    }
    _treeData.push(node)
  }
  return { _treeData, _allKeys }
}


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useSiteZoneTreeSelector({ name, siteFieldName, companyId, siteId, zoneId, setFieldValue, classes }) {
  const treeRef = useRef()

  const { fetchData, data, allDataLoaded } = useProgressiveDataLoader({
    query: GET_ZONES_SITES_LOOKUP_QUERY,
    isImmediate: false,
  })

  const [{ treeData, allSites, autoExpandParent, expandedKeys, checkedKeys }, setState] = useReducer(reducer, {
    treeData: [],
    allSites: [],
    autoExpandParent: true,
    expandedKeys: undefined,
    checkedKeys: undefined,
  })

  useEffect(() => {
    if (companyId) {
      fetchData({
        variables: {
          page: 1,
          companyId,
        },
      })
    }
  }, [companyId, fetchData])


  useEffect(() => {
    if (data) {
      const __allSitesMap = new Map()
      for (const zone of data) {
        if (zone.site?.id) {
          if (!__allSitesMap.has(zone.site.id)) {
            __allSitesMap.set(zone.site.id, {
              ...zone.site,
              zones: [omit(zone, 'site')],
            })
          } else {
            const _zoneRef = __allSitesMap.get(zone.site.id)
            _zoneRef.zones.push(omit(zone, 'site'))
          }
        }
      }
      for (const [key, value] of __allSitesMap.entries()) {
        __allSitesMap.set(key, { ...value, zones: sortBy(value.zones, 'name') })
      }
      const __allSites = sortBy([...__allSitesMap.values()], 'name')
      setState({
        allSites: __allSites,
      })
    }
  }, [data])

  const selectByKeys = useCallback((_checkedKeys) => {
    const siteIds = []
    const zoneIds = []
    for (const _checkedKey of _checkedKeys) {
      const keyObj = JSON.parse(_checkedKey)
      if (keyObj.siteId) {
        siteIds.push(keyObj.siteId)
      } else if (keyObj.zoneId) {
        zoneIds.push(keyObj.zoneId)
      }
    }
    setFieldValue( siteFieldName ?? 'siteId', siteIds);
    setFieldValue(name ?? 'zoneId', zoneIds);
    // eslint-disable-next-line
  }, [setFieldValue, name, siteFieldName])

  useEffect(() => {
    if (allSites) {
      const { _treeData, _allKeys } = buildTree(allSites, classes)
      setState({
        treeData: _treeData,
        expandedKeys: _allKeys,
      })
    }
    // eslint-disable-next-line
  }, [allSites, selectByKeys])


  useEffect(() => {
    if (treeData && treeData.length > 0) {
      const _checkedKeys = []
      if (zoneId && Array.isArray(zoneId)) {
        for (const id of zoneId) {
          _checkedKeys.push(JSON.stringify({ zoneId: id }))
        }
      }
      setState({
        checkedKeys: _checkedKeys,
      })
    }
  }, [zoneId, treeData])

  const onCheck = useCallback((_checkedKeys) => {
    selectByKeys(_checkedKeys)
    // eslint-disable-next-line
  }, [treeData, selectByKeys])

  const onExpand = useCallback((_expandedKeys) => {
    setState({
      expandedKeys: _expandedKeys,
    })
    // eslint-disable-next-line
  }, []);

  const onSelectDeselectAll = useCallback((e)=>{
    if (e.target.checked === true){
      if (treeData && treeData.length > 0) {
        const treemap = new Map();
        treeData.forEach((item=>{
          treemap.set(item.key, true);
          if (item.children){
            item.children.forEach((innerItem=>{
              treemap.set(innerItem.key, true);
            }));
          }
        }));
        const _checkedKeys = [...treemap.keys()];
        setState({
          checkedKeys: _checkedKeys
        });
        selectByKeys(_checkedKeys)
      }
    } else {
     setState({
        checkedKeys: []
      });
      selectByKeys([])
    }
  },[treeData, selectByKeys]);

  return {
    treeRef,
    treeData,
    expandedKeys,
    checkedKeys,
    onCheck,
    onExpand,
    autoExpandParent,
    onSelectDeselectAll,
    allDataLoaded,
  }

}

const SiteZonesTreeSelector = React.memo(function({siteFieldName, name, companyId, siteId, zoneId, setFieldValue, height }) {
  const classes = useStyles()
  const { treeRef, treeData, allDataLoaded, expandedKeys, checkedKeys, onCheck, onExpand, onSelectDeselectAll } = useSiteZoneTreeSelector({
    companyId,
    siteId,
    zoneId,
    setFieldValue,
    classes,
    name,
    siteFieldName,
  })

  if (!allDataLoaded) {
    return <div>
      <CircularProgress size={20} />
    </div>
  }

  return (<div>
    {treeData && treeData.length > 0 && (
      <FormGroup check>
        <Label check>
          <Input onChange={onSelectDeselectAll} type="checkbox" className={classes.selectAllInput} />
          Select/De-Select all
        </Label>
      </FormGroup>
    )}
    <Tree
      ref={treeRef}
      checkable
      multiple
      defaultCheckedAll
      expandedKeys={expandedKeys}
      checkedKeys={checkedKeys}
      treeData={treeData}
      showIcon
      onCheck={onCheck}
      onExpand={onExpand}
      height={height ?? 400}
    />
  </div>)
})


export default SiteZonesTreeSelector
