import { useRef, useEffect } from 'react';
import { noop } from 'lodash'


function useJsonCompareMemo (value) {
  const ref = useRef(value);
  if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useJsonCompareEffect (hookFn = noop, deps){
  //eslint-disable-next-line
  useEffect(hookFn, useJsonCompareMemo(deps));
}


export default useJsonCompareEffect;
