import React from 'react'
import ContentLoader from 'react-content-loader'

const CardLoader = props => {
  return (
      <div style={{ width: "100%", height: "40px" }}>
        <ContentLoader
          style={{ width: "100%", height: "100%" }}
          speed={2}
          {...props}
        >
        <rect x="0" y="10" rx="0" ry="0" width="100%" height="10" />
      </ContentLoader>
      </div>
  )
}

export default CardLoader
