import { addSeconds, differenceInCalendarDays } from 'date-fns'

export const timeRangesListLong = Object({
  "now-5m": "Last 5 minutes",
  "now-1h": "Last 1 hour",
  "now-12h": "Last 12 hour",
  "now-24h": "Last 24 hours",
  "now-3d": "Last 3 days",
  "now-7d": "Last 7 days",
  "now-1M": "Last 1 month",
  "now-2M": "Last 2 month",
  "now-3M": "Last 3 months",
  "now-6M": "Last 6 months",
  custom: "Custom"
});


export const timeRangesListShort = Object({
  "now-1h": "Last 1 hour",
  "now-12h": "Last 12 hour",
  "now-24h": "Last 24 hours",
  "now-3d": "Last 3 days",
  "now-7d": "Last 7 days",
  "now-1M": "Last 1 month",
  "now-2M": "Last 2 month",
  "now-3M": "Last 3 months",
  "now-6M": "Last 6 months",
});

function parseInterval(timeStr){
  const now = Math.round(Date.now() / 1000);
  const timeRanges = Object({
    'now-5m': now - 60 * 5,
    'now-1h': now - 60 * 60,
    "now-12h": now - 60 * 60 * 12,
    'now-24h': now - 60 * 60 * 24,
    'now-3d': now - 60 * 60 * 24 * 3,
    'now-7d': now - 60 * 60 * 24 * 6,
    'now-1M': now - 60 * 60 * 24 * 30,
    'now-2M': now - 60 * 60 * 24 * 30 * 2,
    'now-3M': now - 60 * 60 * 24 * 30 * 3,
    'now-6M': now - 60 * 60 * 24 * 30 * 6
  });

  if (timeStr === 'now') {
    return now * 1000;
  }
  return (timeRanges[timeStr] || parseInt(timeStr) || now) * 1000;
};

export const defaultTo = () => {
  const end = new Date()
  end.setUTCHours(23, 59, 59, 99)
  return Math.round(end / 1000).toString()
}

export const addSecondToStartOfTimeStamp = function  (inputSec){
  const _time = new Date();
  _time.setHours(0, 0, 0, 0);
  return addSeconds(_time, inputSec);
}

const dateDifferenceInDays = function(from, to){
  return Math.abs(differenceInCalendarDays(new Date(from), new Date(to)));
}


export default {parseInterval, dateDifferenceInDays}
