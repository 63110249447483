import { useParams } from 'react-router-dom'
import { useEffect, useReducer } from 'react'
import { useLocation } from 'react-use'
import urlHelper from '../../../helpers/urlHelper'

function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useHandleInitialInvitationData() {

  const [{ initialValues }, setState] = useReducer(reducer, {
    initialValues: {},
  })

  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    if (params && params.userName && params.token) {
      const { userName, token } = params
      const email = atob(userName.toString())
      const _firstName = urlHelper.extractFromUrl('firstName', location)
      const _lastName = urlHelper.extractFromUrl('lastName', location)
      const _newState = {
        email,
        password: token,
      }
      if (_firstName && _firstName.length > 0) {
        _newState.firstName = atob(_firstName.toString())
      }
      if (_lastName && _lastName.length > 0) {
        _newState.lastName = atob(_lastName.toString())
      }
      setState({
        initialValues: _newState,
      })
    } else {
      setState({
        initialValues: {},
      })
    }
    // eslint-disable-next-line
  }, [params])


  return { initialValues }

}

export default useHandleInitialInvitationData
