import { useCallback, useEffect, useReducer, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { isUndefined, isEqual } from "lodash";
// eslint-disable-next-line import/no-cycle
import useDebouncedEffect from "./useDebouncedEffect";

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

export default function useProgressiveDataLoader({
                                                   query,
                                                   initialQuery,
                                                   defaultAccessorProperty,
                                                   isImmediate = false,
                                                   queryOption,
                                                 }) {

  const onLoadSubscriberRef = useRef(null);

  const [loadData, {
    data: loadedData,
    loading: dataLoading,
    error
  }] = useLazyQuery(query, queryOption ?? {});

  const [{
    data,
    loading,
    progress,
    localData,
    defaultQuery,
    allDataLoaded,
    lastUsedVariables,
    tick
  }, setState] = useReducer(reducer, {
    data: undefined,
    localData: undefined,
    lastUsedVariables: undefined,
    allDataLoaded: false,
    defaultQuery: initialQuery,
    loading: false,
    progress: 0,
    tick: undefined
  });

  useEffect(() => {
    return () => {
      if (onLoadSubscriberRef.current) {
        onLoadSubscriberRef.current = null;
      }
    };
  }, []);

  useDebouncedEffect(() => {
    if (initialQuery && defaultQuery && !isEqual(initialQuery, defaultQuery)) {
      setState({
        defaultQuery: initialQuery
      });
    }
  }, 200, [initialQuery, defaultQuery]);

  const fetchData = useCallback(({ variables, onLoadSubscription }) => {
    if (variables) {
      const _state = {
        lastUsedVariables: { ...variables }
      };
      if (variables && variables.page === 1) {
        _state.loading = true;
        _state.progress = 0;
        _state.allDataLoaded = false;
        _state.tick = Date.now();
      }
      setState(_state);
      if (onLoadSubscription) {
        onLoadSubscriberRef.current = onLoadSubscription;
      }
      loadData({ variables });
    }
  }, [loadData]);


  const parseResults = useCallback((results) => {
    if (results && Object.values(results).length > 0) {
      let originalResults = Object.values(results)[0];
      if (!originalResults) {
        originalResults = {};
      }
      let _localData = localData && Array.isArray(localData) ? [...localData] : [];
      const {
        pages,
        page,
        nodes
      } = !isUndefined(defaultAccessorProperty) ? (originalResults[defaultAccessorProperty] || {}) : originalResults;
      if (page === 1) {
        _localData = nodes && Array.isArray(nodes) ? [...nodes] : nodes;
      } else if (nodes && Array.isArray(nodes)) {
        _localData = [..._localData, ...nodes];
      }
      if (page < pages) {
        const _obj = {
          localData: _localData,
          allDataLoaded: false,
          progress: Math.min(((page / pages) * 100), 100)
        };
        if (isImmediate) {
          _obj.data = _localData;
        }
        setState(_obj);
        fetchData({ variables: { ...lastUsedVariables, page: page + 1 } });
      } else {
        setState({
          localData: _localData,
          data: _localData,
          allDataLoaded: true,
          progress: 100
        });
        if (onLoadSubscriberRef.current) {
          onLoadSubscriberRef.current.next({ data: _localData });
          onLoadSubscriberRef.current.complete();
        }
      }
    } else {
      setState({
        localData: undefined,
        data: undefined,
        allDataLoaded: true
      });
      if (onLoadSubscriberRef.current) {
        onLoadSubscriberRef.current.next({ data: [] });
        onLoadSubscriberRef.current.complete();
      }
    }
    // eslint-disable-next-line
  }, [lastUsedVariables, onLoadSubscriberRef, localData, fetchData, defaultAccessorProperty]);


  useEffect(() => {
    if (error) {
      setState({
        loading: false
      });
    } else {
      if (allDataLoaded && !dataLoading) {
        setState({
          loading: false
        });
      }
    }
  }, [allDataLoaded, dataLoading, error]);


  /*useDebouncedEffect(() => {
    if (dataLoading) {
      setState({
        loading: true
      });
    } else {
      setState({
        loading: false
      });
    }
  }, 100, [dataLoading]);*/

  useDebouncedEffect(() => {
    if (loadedData) {
      parseResults(loadedData);
    }
    // eslint-disable-next-line
  }, 10, [loadedData, tick]);

  const clearAllData = useCallback(()=>{
    setState({
      data: [],
    })
  }, [])

  useDebouncedEffect(() => {
    if (defaultQuery) {
      fetchData({
        variables: defaultQuery
      });
    }
  }, 700, [fetchData, defaultQuery]);

  return {
    fetchData,
    data,
    loading,
    progress,
    error,
    allDataLoaded,
    defaultQuery,
    clearAllData,
  };
}
