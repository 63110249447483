import {isNumber, isFinite, round, groupBy, meanBy, mean} from 'lodash';

function calculateAvgPerformance(devicePerformances) {
  let avg = 0;
  if (Array.isArray(devicePerformances) && devicePerformances.length > 0) {
    const newMetrics =  devicePerformances.filter(item=> {
      return item && !item.outOfServiceAt;
    }).map((item)=>{
      return  {...item};
    });
    avg = round(meanBy(newMetrics, 'optimalPercentage'), 1);
  }
  return avg;
}

const fieldNames = [
  'optimalBelowLowCount',
  'optimalCount',
  'optimalAboveHighCount',
  'totalCount',
  'incidentBelowLowCount',
  'incidentAboveHighCount',
  'optimalBelowLowPercentage',
]

function mergePerformances(devicePerformances, propertyName) {
  const newDevicePerformances = [];
  if (Array.isArray(devicePerformances)) {
    const groupedData = groupBy(devicePerformances, propertyName)
    Object.keys(groupedData).forEach(keyName => {
      const newPerformance = {}
      fieldNames.forEach(field => {
        newPerformance[field] = 0
      })
      groupedData[keyName].forEach((data, index) => {
        if (index === 0) {
          newPerformance.propertyGroupName = data.propertyGroupName
          newPerformance.propertyName = data.propertyName
          if (data.assetName) {
            newPerformance.assetName = data.assetName
          }
          if (data.zoneName) {
            newPerformance.zoneName = data.zoneName
          }
          if (data.siteName) {
            newPerformance.siteName = data.siteName
          }
        }
        fieldNames.forEach(field => {
          if (isFinite(data[field])) {
            newPerformance[field] += data[field]
          }
        })
      })
      newPerformance.optimalPercentage = round(((newPerformance.optimalCount / newPerformance.totalCount) * 100), 1)
      newPerformance.optimalBelowLowPercentage = round(((newPerformance.optimalBelowLowCount / newPerformance.totalCount) * 100), 1)
      newPerformance.optimalAboveHighPercentage = round((newPerformance.optimalAboveHighCount / newPerformance.totalCount) * 100, 1)
      newPerformance.incidentBelowLowPercentage = round((newPerformance.incidentBelowLowCount / newPerformance.totalCount) * 100, 1)
      newPerformance.incidentAboveHighPercentage = round((newPerformance.incidentAboveHighCount / newPerformance.totalCount) * 100, 1)

      if (isNaN(newPerformance.optimalPercentage)){
        newPerformance.optimalPercentage = 0;
      }
      if (isNaN(newPerformance.optimalBelowLowPercentage)){
        newPerformance.optimalBelowLowPercentage = 0;
      }
      if (isNaN(newPerformance.optimalAboveHighPercentage)){
        newPerformance.optimalAboveHighPercentage = 0;
      }
      if (isNaN(newPerformance.incidentBelowLowPercentage)){
        newPerformance.incidentBelowLowPercentage = 0;
      }
      if (isNaN(newPerformance.incidentAboveHighPercentage)){
        newPerformance.incidentAboveHighPercentage = 0;
      }
      newPerformance[propertyName] = keyName
      newDevicePerformances.push(newPerformance)
    })
  }
  return newDevicePerformances
}

function mergeFlatPerformances(devicePerformances) {
  const newPerformance = {};
  fieldNames.forEach(field => {
    newPerformance[field] = 0
  });
  let counter = 0
  if (Array.isArray(devicePerformances)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const devicePerformance of devicePerformances) {
      if (counter === 0) {
        newPerformance.propertyGroupName = devicePerformance.propertyGroupName
        newPerformance.propertyName = devicePerformance.propertyName
        if (devicePerformance.assetName) {
          newPerformance.assetName = devicePerformance.assetName
        }
        if (devicePerformance.zoneName) {
          newPerformance.zoneName = devicePerformance.zoneName
        }
        if (devicePerformance.siteName) {
          newPerformance.siteName = devicePerformance.siteName
        }
      }
      fieldNames.forEach((field) => {
          if (isFinite(devicePerformance[field])) {
            newPerformance[field] += devicePerformance[field]
          }
      });
      counter++
    }
  }
  if (newPerformance.totalCount) {
    newPerformance.optimalPercentage = round(((newPerformance.optimalCount / newPerformance.totalCount) * 100), 1)
    newPerformance.optimalBelowLowPercentage = round((newPerformance.optimalBelowLowCount / newPerformance.totalCount) * 100, 1)
    newPerformance.optimalAboveHighPercentage = round((newPerformance.optimalAboveHighCount / newPerformance.totalCount) * 100, 1)
    newPerformance.incidentBelowLowPercentage = round((newPerformance.incidentBelowLowCount / newPerformance.totalCount) * 100, 1)
    newPerformance.incidentAboveHighPercentage = round((newPerformance.incidentAboveHighCount / newPerformance.totalCount) * 100, 1)
    return [newPerformance];
  }
  return [];
}

function getPerformanceStatus(avgPerformance, kpiSettingsLookupData) {
  let status = 'KPI not set'
  let icon = 'icon question circle grey'
  if (isNumber(avgPerformance) && kpiSettingsLookupData && Array.isArray(kpiSettingsLookupData)) {
    for (const performance of kpiSettingsLookupData) {
      if (avgPerformance >= performance.valueLow && avgPerformance <= performance.valueHigh) {
        status = performance.name
        if (performance.icon) {
          icon = performance.icon
        }
        break
      }
    }
  }
  return { status, icon }
}


function mergeSummaryPerformances(devicePerformances, propertyName) {
  const newDevicePerformances = [];
  if (Array.isArray(devicePerformances)) {
    const groupedData = groupBy(devicePerformances, propertyName)
    Object.keys(groupedData).forEach(keyName => {
      const newPerformance = {}
      fieldNames.forEach(field => {
        newPerformance[field] = 0
      })
      const minArray = [];
      const maxArray = [];
      const avgArray = [];
      groupedData[keyName].forEach((data, index) => {
        if (index === 0) {
          newPerformance.propertyGroupName = data.propertyGroupName
          newPerformance.propertyName = data.propertyName
          if (data.assetName) {
            newPerformance.assetName = data.assetName
          }
          if (data.zoneName) {
            newPerformance.zoneName = data.zoneName
          }
          if (data.siteName) {
            newPerformance.siteName = data.siteName
          }
        }
        if (isFinite(data.min)) {
          minArray.push(data.min);
        }
        if (isFinite(data.max)) {
          maxArray.push(data.max);
        }
        if (isFinite(data.avg)) {
          avgArray.push(data.avg);
        }
        fieldNames.forEach(field => {
          if (isFinite(data[field])) {
            newPerformance[field] += data[field]
          }
        })
      })
      newPerformance.optimalPercentage = round(((newPerformance.optimalCount / newPerformance.totalCount) * 100), 1)
      newPerformance.optimalBelowLowPercentage = round(((newPerformance.optimalBelowLowCount / newPerformance.totalCount) * 100), 1)
      newPerformance.optimalAboveHighPercentage = round((newPerformance.optimalAboveHighCount / newPerformance.totalCount) * 100, 1)
      newPerformance.incidentBelowLowPercentage = round((newPerformance.incidentBelowLowCount / newPerformance.totalCount) * 100, 1)
      newPerformance.incidentAboveHighPercentage = round((newPerformance.incidentAboveHighCount / newPerformance.totalCount) * 100, 1)

      if (isNaN(newPerformance.optimalPercentage)){
        newPerformance.optimalPercentage = 0;
      }
      if (isNaN(newPerformance.optimalBelowLowPercentage)){
        newPerformance.optimalBelowLowPercentage = 0;
      }
      if (isNaN(newPerformance.optimalAboveHighPercentage)){
        newPerformance.optimalAboveHighPercentage = 0;
      }
      if (isNaN(newPerformance.incidentBelowLowPercentage)){
        newPerformance.incidentBelowLowPercentage = 0;
      }
      if (isNaN(newPerformance.incidentAboveHighPercentage)){
        newPerformance.incidentAboveHighPercentage = 0;
      }

      newPerformance.min = Math.min(...minArray)
      newPerformance.max = Math.max(...maxArray)
      newPerformance.avg = round(mean(avgArray), 1)

      newPerformance[propertyName] = keyName
      newDevicePerformances.push(newPerformance)
    })
  }
  return newDevicePerformances
}


/**
 * This setting is configured as Minute value. This is why we are converting into second first.
 * @param avgPerformance
 * @param kpiSettingsLookupData
 * @returns {{icon: string, status: string}}
 */
function getIncidentAckTimePerformanceStatus(avgPerformance, kpiSettingsLookupData) {
  let status = 'KPI not set'
  let icon = 'icon question circle grey'
  if (isNumber(avgPerformance) && kpiSettingsLookupData && Array.isArray(kpiSettingsLookupData)) {
    for (const performance of kpiSettingsLookupData) {
      if (avgPerformance >= performance.valueLow * 60 && avgPerformance <= performance.valueHigh * 60) {
        status = performance.name
        if (performance.icon) {
          icon = performance.icon
        }
        break
      }
    }
  }
  return { status, icon }
}

function mergeGroupedPerformances(devicePerformances, propertyName) {
  const newDevicePerformances = []
  if (Array.isArray(devicePerformances)) {
    const groupedData = groupBy(devicePerformances, propertyName)
    Object.keys(groupedData).forEach((keyName) => {
      let optimalCount = 0
      let totalCount = 0
      let optimalBelowLowCount = 0;
      let optimalAboveHighCount = 0;
      let incidentBelowLowCount = 0;
      let incidentAboveHighCount = 0;
      const newPerformance = {}
      newPerformance[propertyName] = keyName
      const dataItems = groupedData[keyName]
      dataItems.forEach((data, index) => {
        optimalCount += data.optimalCount
        totalCount += data.totalCount
        optimalBelowLowCount += data.optimalBelowLowCount
        optimalAboveHighCount += data.optimalAboveHighCount
        incidentBelowLowCount += data.incidentBelowLowCount
        incidentAboveHighCount += data.incidentAboveHighCount
      });
      const optimalPercentage = (optimalCount / totalCount) * 100
      newPerformance.optimalCount = optimalCount;
      newPerformance.totalCount = totalCount;
      newPerformance.optimalBelowLowCount = optimalBelowLowCount;
      newPerformance.optimalAboveHighCount = optimalAboveHighCount;
      newPerformance.incidentAboveHighCount = incidentAboveHighCount;
      newPerformance.incidentBelowLowCount = incidentBelowLowCount;
      newPerformance.optimalPercentage = round(optimalPercentage, 1);
      newDevicePerformances.push(newPerformance);
    });
  }
  return newDevicePerformances
}

export {
  // eslint-disable-next-line import/prefer-default-export
  calculateAvgPerformance,
  getPerformanceStatus,
  mergePerformances,
  mergeFlatPerformances,
  getIncidentAckTimePerformanceStatus,
  mergeGroupedPerformances,
  mergeSummaryPerformances,
}
