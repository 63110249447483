import React, {
  Suspense,
  useCallback,
  useEffect,
  useReducer,
  useState
} from 'react';
import GlobalContext from '../providers/GlobalContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Spotlight from './search/Spotlight';
import MainNavBar from './MainNavBar';
import CustomReportGenerator from './custom-exports/CustomReportGenerator';
import Topbar from './Topbar';
import { isUserAuthenticated } from '../helpers/authUtils';
import Loader from './loaders/Loader';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52

const loading = () => <div className='text-center' />;

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

const AuthLayout = React.memo(({ children }) => {
  const location = useLocation();
  const {
    loggedInUser,
    loggedInUserLoading,
    getLoggedInUserQuery,
    clearSession
  } = React.useContext(GlobalContext);
  const [showLoginPage, setShowLoginPage] = useState(false);

  const [{ isMenuOpened }, setState] = useReducer(reducer, {
    isMenuOpened: false
  });


  useEffect(() => {
    const checkLoggedInUser = async () => {
      try {
        const result = await getLoggedInUserQuery({
          variables: {}
        }).catch(err => {
          clearSession();
          setShowLoginPage(true);
        });
        if (!result?.data?.me) {
          clearSession();
          setShowLoginPage(true);
        }
      } catch (err) {
        clearSession();
        setShowLoginPage(true);
      }
    };
    if (isUserAuthenticated()) {
      checkLoggedInUser();
    }
    // eslint-disable-next-line
  }, []);

  const toggleMenu = useCallback((e) => {
    e.preventDefault();
    setState({ isMenuOpened: !isMenuOpened });
  }, [isMenuOpened]);


  if (showLoginPage) {
    return (<Navigate
      to={'/login'}
      replace
      state={{ from: location.pathname, search: location.search }}
    />);
  }


  if (loggedInUserLoading) {
    return (<Loader />);
  }

  if (!loggedInUser) {
    return null;
  }


  return (
    <div className='app'>
      <header id='topnav'>
        <Spotlight />
        <Topbar menuToggle={toggleMenu} isMenuOpened={isMenuOpened} />
        <CustomReportGenerator />
      </header>
      <MainNavBar />
      <Suspense fallback={loading()}>
        <Outlet />
        {children}
      </Suspense>
    </div>
  );
});


export default AuthLayout;
