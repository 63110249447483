import React, {
  useCallback, useContext, useEffect,
  useLayoutEffect,
  useReducer,
} from 'react'
import { groupBy, sortBy, isNumber } from 'lodash'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useLazyQuery } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router-dom';
import Hits from './Hits'
import SearchBar from './SearchBar'
import SpotlightContext from './SpotlightContext'
import {
  GET_GLOBAL_SEARCH_QUERY,
} from '../../constants/queries/dashboard'
import GlobalContext from '../../providers/GlobalContext'
import useDebouncedEffect from '../../hooks/useDebouncedEffect'

const DEFAULT_STATE = {
  hits: {},
  flatHits: [],
  selectedResultIndex: 0,
  searchValue: undefined,
}

function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

const Spotlight = React.memo(function Spotlight(props) {

  const { selectedCompany, showGlobalSearchBar,
    setShowGlobalSearchBar } = useContext(GlobalContext)

  const navigate = useNavigate();

  const [getGlobalSearchQuery, { data: searchResults, loading }] = useLazyQuery(GET_GLOBAL_SEARCH_QUERY, {})

  const [{
    hits,
    flatHits,
    selectedResultIndex,
    searchValue,
  }, setState] = useReducer(reducer, DEFAULT_STATE)

  const toggle = useCallback(() => {
    setShowGlobalSearchBar({
      showGlobalSearchBar: !showGlobalSearchBar
    })
    // eslint-disable-next-line
  }, [setShowGlobalSearchBar])


  const _listenKey = useCallback((event) => {
    const isCtrlSpace = event.keyCode === 32 && event.ctrlKey
    if (!isCtrlSpace) {
      return
    }
    toggle()
    event.preventDefault()
  }, [toggle])


  useEffect(() => {
    if (searchResults && Object.values(searchResults).length > 0) {
      const results = Object.values(searchResults)[0]
      let flatIndex = 0
      let _flatHits = [];
      const _hits = groupBy(results, 'type');
      Object.keys(_hits).forEach((keyName)=>{
        _hits[keyName] = sortBy(_hits[keyName], 'name').map(item => {
          return { ...item, flatIndex: flatIndex++ }
        })
      });
      _flatHits = sortBy(Object.values(_hits).flat(), flatIndex);
      setState({
        flatHits: _flatHits,
        hits: _hits,
        selectedResultIndex: 0,
      })
    }
  }, [searchResults])

  useLayoutEffect(() => {
    document.body.addEventListener('keydown', _listenKey)
    return () => {
      document.body.removeEventListener('keydown', _listenKey)
    }
  }, [_listenKey])

  const clearSearch = useCallback((close) => {
    setState({ ...DEFAULT_STATE})
    setShowGlobalSearchBar(!close);
  }, [setShowGlobalSearchBar])

  const closeSearchBar = useCallback(() => {
    setState({ ...DEFAULT_STATE})
    setShowGlobalSearchBar(false);
  }, [setShowGlobalSearchBar])

  const selectHit = useCallback((_selectedResultIndex) => {
    if (isNumber(_selectedResultIndex) && flatHits?.length > 0) {
      const _hit = flatHits[_selectedResultIndex]
      if (_hit && _hit.id && _hit.companyId && _hit.type) {
        switch (_hit.type) {
          case 'asset':
            navigate(`/company/${_hit.companyId}/asset/${_hit.id}`)
            closeSearchBar()
            break
          case 'site':
            navigate(`/company/${_hit.companyId}/site/${_hit.id}`)
            closeSearchBar()
            break
          case 'zone':
            navigate(`/company/${_hit.companyId}/zone/${_hit.id}`)
            closeSearchBar()
            break
          case 'device':
            navigate(`/company/${_hit.companyId}/device/${_hit.id}`)
            closeSearchBar()
            break
          default:
            break
        }
      }
    }
  }, [flatHits, navigate, closeSearchBar])


  const selectUp = useCallback(() => {
    if (selectedResultIndex > 0) {
      setState({ selectedResultIndex: selectedResultIndex - 1 })
      return
    }
    setState({ selectedResultIndex: flatHits.length - 1 })
  }, [selectedResultIndex, flatHits])

  const selectDown = useCallback(() => {
    if (selectedResultIndex < flatHits.length - 1) {
      setState({ selectedResultIndex: selectedResultIndex + 1 })
      return
    }
    setState({ selectedResultIndex: 0 })
  }, [selectedResultIndex, flatHits])

  const performSearch = useCallback(async (search) => {
    const _newSearch = search.toString().trim()
    if (searchValue !== _newSearch) {
      setState({
        searchValue: _newSearch,
      })
    }
  }, [searchValue])


  useDebouncedEffect(() => {
    if (searchValue && searchValue.length > 2) {
      getGlobalSearchQuery({
        variables: {
          search: `%${searchValue}%`,
          companyId: selectedCompany?.id,
        },
      })
    } else {
      setState({
        flatHits: [],
        hits: [],
      })
    }
  }, 400, [searchValue, selectedCompany])

  const handleKeyUp = useCallback((input) => {
    if (!input) {
      return
    }
    if (!input) {
      clearSearch()
    } else {
      performSearch(input)
    }
  }, [clearSearch, performSearch])


  const handleKeyDown = useCallback((event) => {
    switch (event.key) {
      case 'ArrowUp':
        selectUp()
        event.preventDefault()
        break
      case 'ArrowDown':
        selectDown()
        event.preventDefault()
        break
      case 'Tab':
        if (event.shiftKey) {
          selectUp()
          event.preventDefault()
        } else {
          selectDown()
          event.preventDefault()
        }
        break
      case 'Escape':
        clearSearch(true);
        event.preventDefault()
        break
      case 'Enter':
        if (isNumber(selectedResultIndex)) {
          selectHit(selectedResultIndex);
          event.preventDefault();
        }
        break
      default:
    }
  }, [selectUp, selectDown, clearSearch, selectedResultIndex, selectHit])


  if (!showGlobalSearchBar) {
    return null
  }


  return (
    <SpotlightContext.Provider value={
      {
        hits,
        flatHits,
        isOpen: showGlobalSearchBar,
        selectedResultIndex,
        clearSearch,
        selectDown,
        selectUp,
        selectHit,
        handleKeyDown,
        handleKeyUp,
        performSearch,
        closeSearchBar,
        loading,
        setSearchBarState: setState,
      }
    }>
      <div className="spotlight-overlay">
        <ClickAwayListener onClickAway={closeSearchBar}>
          <div className="spotlight-bar">
            <SearchBar/>
            <Hits/>
          </div>
        </ClickAwayListener>
      </div>
    </SpotlightContext.Provider>

  )

})


export default Spotlight
