import React, { useEffect, useReducer } from 'react'
import {
  NavLink,
  useLocation, useParams
} from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Badge, Col, Container, Row } from 'reactstrap'
import { useLazyQuery } from '@apollo/client'
import GlobalContext from '../providers/GlobalContext'
import {
  GET_COMPANY_LOOKUP_QUERY
} from '../constants/queries/lookup'
import { GET_COMPANY_STATS_QUERY } from '../constants/queries/dashboard'
import {
  calculateCumulativeCompanyStatsData
} from '../helpers/companyStatsHelper'
import { useDebouncedEffect } from '../hooks'
import * as classnames from 'classnames'
import { isFunction } from 'lodash'


const checkActiveTab = (selectedTabName, name) => {
  return selectedTabName === name
}

const TAB_NAMES = {
  OVERVIEW: 'OVERVIEW',
  ASSETS: 'ASSETS',
  DEVICES: 'DEVICES',
  INCIDENTS: 'INCIDENTS',
  COMPLIANCE: 'COMPLIANCE',
  REPORT: 'REPORT',
  LOGISTICS: 'LOGISTICS'
}

function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

const MainNavBar = React.memo(() => {


  const location = useLocation()
  const params = useParams()
  const [getCompanyQuery, { data: companyData }] = useLazyQuery(GET_COMPANY_LOOKUP_QUERY, {})
  const [getCompanyStatsQuery, { data: companyStatsData, stopPolling: stopCompanyStatsPooling }] = useLazyQuery(GET_COMPANY_STATS_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 30000,
  })

  const { loggedInUser, setGlobalState } = React.useContext(GlobalContext)

  const [{
    company,
    isManageMode,
    incidentCountData,
    systemCheckCountData,
    isAdminMode,
    selectedTabName,
    isReportMode,
    companyId
  }, setState] = useReducer(reducer, {
    isAdminMode: false,
    isManageMode: false,
    company: undefined,
    companyId: undefined,
    asset: undefined,
    assetId: undefined,
    site: undefined,
    siteId: undefined,
    zone: undefined,
    zoneId: undefined,
    incidentId: undefined,
    incident: undefined,
    companyTextWidth: 50,
    isReportMode: false,
    selectedTabName: '',
    incidentCountData: undefined,
    systemCheckCountData: undefined
  })

  useDebouncedEffect(() => {
    const { pathname } = location
    if (pathname) {
      if (/admin\/manage/.test(pathname)) {
        setState({
          companyId: params.companyId,
          isAdminMode: true,
          isManageMode: false,
          subRoutes: undefined,
          isReportMode: false
        })
      } else if (/manage\/company/.test(pathname) && params.companyId) {
        const _subRoutes = []
        let results = /\/manage\/company\/\d+\/([\w-]+)\/([\w-]+)\/([\d]+)/.exec(pathname)
        const updatedState = {
          assetId: undefined,
          siteId: undefined,
          zoneId: undefined,
          asset: undefined,
          zone: undefined,
          site: undefined,
          companyId: undefined,
          isReportMode: false,
          isManageMode: true,
          isAdminMode: false,
          selectedTabName: ''
        }
        if (!results) {
          results = /\/manage\/company\/\d+\/([\w-]+)\/([\w-]+)/.exec(pathname)
        }
        if (results && results.length > 0) {
          results = results.slice(1)
          if (results[2]) {
            const domainName = results[1]
            const idValue = results[2]
            if (domainName && idValue) {
              if (domainName === 'site') {
                updatedState.siteId = idValue
              } else if (domainName === 'asset') {
                updatedState.assetId = idValue
              } else if (domainName === 'zone') {
                updatedState.zoneId = idValue
              } else {
                updatedState.companyId = params.companyId
              }
            }
          } else {
            updatedState.companyId = params.companyId
          }
        } else {
          updatedState.companyId = params.companyId
        }
        setState({
          isAdminMode: false,
          isManageMode: true,
          subRoutes: _subRoutes,
          isReportMode: undefined,
          ...updatedState,
          companyId: params.companyId
        })
      } else if (/reports-view/.test(pathname)) {
        setState({
          incidentId: params.id,
          isAdminMode: false,
          isManageMode: false,
          subRoutes: undefined,
          isReportMode: true
        })
      } else {
        if (/\/company\/\d+\/asset/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.ASSETS,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/trip/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.ASSETS,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/device/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.DEVICES,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/incidents/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.INCIDENTS,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/incident/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.INCIDENTS,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/report/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.REPORT,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/site/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.OVERVIEW,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/zone/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.OVERVIEW,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/logistics/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.LOGISTICS,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+\/compliance/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.COMPLIANCE,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        } else if (/\/company\/\d+/.test(pathname) && params.companyId) {
          setState({
            companyId: params.companyId,
            selectedTabName: TAB_NAMES.OVERVIEW,
            isAdminMode: false,
            isManageMode: false,
            subRoutes: undefined,
            isReportMode: undefined
          })
        }
      }
    }
  }, 400, [params, location])


  useDebouncedEffect(() => {
    // eslint-disable-next-line
    if (companyId && (!company || company && company.id !== companyId)) {
      getCompanyQuery({ variables: { id: companyId, time: Date.now() } })
    }
    // eslint-disable-next-line
  }, 100, [companyId, company, isManageMode, isAdminMode, isReportMode])

  useDebouncedEffect(() => {
    if (companyId) {
      getCompanyStatsQuery({
        variables: { id: companyId }
      });
      return ()=>{
        stopCompanyStatsPooling && stopCompanyStatsPooling();
      }
    }
    // eslint-disable-next-line
  }, 100, [companyId])

  useEffect(()=>{
    if (isFunction(stopCompanyStatsPooling)) {
      return () => {
        if (isFunction(stopCompanyStatsPooling)) {
          stopCompanyStatsPooling();
        }
      }
    }
  }, [stopCompanyStatsPooling])


  useDebouncedEffect(() => {
    if (companyData && companyData.company) {
      setState({
        company: { ...companyData.company }
      })
    } else {
      setState({ company: undefined })
    }
    // eslint-disable-next-line
  }, 100, [companyData])

  useDebouncedEffect(() => {
    if (loggedInUser && companyStatsData?.company?.stats) {
      const {
        incidentCountData,
        systemCheckCountData
      } = calculateCumulativeCompanyStatsData({
        loggedInUser,
        companyStatsData
      })
      setState({
        incidentCountData,
        systemCheckCountData
      })
    }
  }, 200, [companyStatsData, loggedInUser])


  useEffect(() => {
    if (company) {
      setGlobalState({
        selectedCompany: company,
        selectedCompanyId: company.id
      })
    }
    // eslint-disable-next-line
  }, [company])


  if (isReportMode || isAdminMode || isManageMode || !companyId) {
    return null
  }


  return (<div className='page-header'>
    <div className='wrapper'>
      <Container fluid>
        <Row>
          <Col>
            <NavLink
              to={`/company/${companyId}`}
              className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.OVERVIEW) ? 'active-tab' : null)}
            >
              Overview
            </NavLink>
            <NavLink
              to={`/company/${companyId}/assets`}
              className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.ASSETS) ? 'active-tab' : null)}
            >
              Assets
            </NavLink>
            <NavLink
              to={`/company/${companyId}/devices`}
              className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.DEVICES) ? 'active-tab' : null)}

            >
              Devices
            </NavLink>
            {company && company.isLogisticsFeatureEnabled === true && (
              <NavLink
                to={`/company/${companyId}/logistics`}
                className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.LOGISTICS) ? 'active-tab' : null)}

              >
                Logistics
              </NavLink>
            )}
            <NavLink
              to={`/company/${companyId}/incidents`}
              className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.INCIDENTS) ? 'active-tab' : null)}

            >
              Incidents
              {incidentCountData?.open > 0 && (
                <Badge className='noti-badge  animate__fadeIn'  color='danger' pill>
                  {incidentCountData.open}
                </Badge>
              )}
            </NavLink>
            <NavLink
              to={`/company/${companyId}/compliance`}
              className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.COMPLIANCE) ? 'active-tab' : null)}
            >
              Compliance
              {systemCheckCountData?.incomplete > 0 && (
                <Badge className='noti-badge  animate__fadeIn'  color='danger' pill>
                  {systemCheckCountData?.incomplete}
                </Badge>
              )}
            </NavLink>
            <NavLink
              to={`/company/${companyId}/report`}
              className={classnames('companyTab', checkActiveTab(selectedTabName, TAB_NAMES.REPORT) ? 'active-tab' : null)}
            >
              Reports
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  </div>)
})

export default MainNavBar
