import { useRef, useEffect, useCallback } from 'react'
import { isFunction } from 'lodash'


function useDebouncedCallback(callback, delay, props) {

  const timeoutRef = useRef(null);

  const callbackFn = useCallback((...args) =>{
    if (timeoutRef.current){
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(()=>{
      if (isFunction(callback)){
        // eslint-disable-next-line prefer-spread
        callback.apply(null, args);
      }
    }, delay);

    // eslint-disable-next-line
  }, [callback, ...props]);

  useEffect(() => {
   return ()=>{
     if (timeoutRef.current){
       clearTimeout(timeoutRef.current);
     }
   }
   // eslint-disable-next-line
  }, [])

  return callbackFn;

}

export default useDebouncedCallback
