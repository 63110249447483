import React, {useEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import useSematicsTheme from './useSematicsTheme'

const DoughnutChart = React.memo(function ({data}){

  const chartObj = useRef()


  useEffect(() => {
    am4core.useTheme(useSematicsTheme);
    // eslint-disable-next-line no-multi-assign
   const chart = chartObj.current = am4core.create("doughnut", am4charts.PieChart);
    // chart.data = [...data]
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "count";
    pieSeries.dataFields.category = "type";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    chart.innerRadius = am4core.percent(55);
    return () => chart && chart.dispose()
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (chartObj.current) {
      chartObj.current.data = data && Array.isArray(data) ? [...data]: [];
    }
  }, [data]);

  return (
    <div id="doughnut" style={{ width: "100%", height: "280px" }} />
  );
});

export default DoughnutChart
