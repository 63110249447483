import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import {pick} from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  MANAGE_ACCEPT_INVITE_USER_MUTATION,
} from '../../../constants/mutations/manage'
import useHandleInitialInvitationData from './useHandleInitialInvitationData'
import { isUserAuthenticated, setSession } from '../../../helpers/authUtils'
import { AUTHENTICATE_USER_MUTATION } from '../../../constants/mutations/auth'
import GlobalContext from '../../../providers/GlobalContext'
import * as Yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { passwordRegex } from '../../../constants/validationRegex'



function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useAcceptInvitationForm() {

  const client = useApolloClient();
  const {setGlobalState} = useContext(GlobalContext);
  const {initialValues } = useHandleInitialInvitationData();
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const [{ success, successMessage, error, errorMessage, isEmailOTPSent, showEmailOTPSentMessage }, setState] = useReducer(reducer, {
    success: false,
    isEmailOTPSent: false,
    showEmailOTPSentMessage: false,
  });
  const [acceptUserInvitation] = useMutation(MANAGE_ACCEPT_INVITE_USER_MUTATION)
  const [loginUser] = useMutation(AUTHENTICATE_USER_MUTATION);

  const ValidationSchema = useMemo(()=> {
      if (isEmailOTPSent){
        return Yup.object().shape({
          email: Yup.string().email().required('Email is required').max(70, 'Must be at lower than 70 characters'),
          firstName: Yup.string().required('First name is required').max(50, 'Must be at lower than 50 characters'),
          lastName: Yup.string().required('Last name is required').max(50, 'Must be at lower than 50 characters'),
          telephone: Yup.string().test({
            name: 'telephone',
            message: 'Phone number is not valid',
            test: (val) => {
              if (val) {
                return isValidPhoneNumber(val, 'GB')
              }
              return true
            },
          }),
          password: Yup.string().required('Password is required'),
          newPassword: Yup.string().required('Enter your new password').min(7, 'Must be at least 7 characters').test({
            name: 'newPassword',
            message: 'Password should contain at least 1 upper case letter, 1 number or special character',
            test: (val) => val && passwordRegex.test(val),
          }),
          rePassword: Yup.string().required('Enter your password again').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
          emailPassCode:  Yup.string().required('Enter otp'),
        })
      } else {
        return Yup.object().shape({
          email: Yup.string().email().required('Email is required').max(70, 'Must be at lower than 70 characters'),
          firstName: Yup.string().required('First name is required').max(50, 'Must be at lower than 50 characters'),
          lastName: Yup.string().required('Last name is required').max(50, 'Must be at lower than 50 characters'),
          telephone: Yup.string().test({
            name: 'telephone',
            message: 'Phone number is not valid',
            test: (val) => {
              if (val) {
                return isValidPhoneNumber(val, 'GB')
              }
              return true
            },
          }),
          password: Yup.string().required('Password is required'),
          newPassword: Yup.string().required('Enter your new password').min(7, 'Must be at least 7 characters').test({
            name: 'newPassword',
            message: 'Password should contain at least 1 upper case letter, 1 number or special character',
            test: (val) => val && passwordRegex.test(val),
          }),
          rePassword: Yup.string().required('Enter your password again').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        })
      }
  }, [isEmailOTPSent])


  const loginUserCallback = useCallback(async function loginUserCallback(setSubmitting, email, password, emailPassCode) {
    await client.clearStore();
    setState({
      error: false,
      errorMessage: undefined,
    });
    const result1 = await loginUser({ variables: {
        email, password, emailPassCode
      }});
    setState({
      loading: false
    });
    if (result1 && result1.data){
      if (result1.data.authenticateUser){
        const data = result1.data.authenticateUser;
        if (data.success) {
          setSession(data);
          setGlobalState({
            loggedInUser: {...data.user}
          })
          const isAuthTokenValid = isUserAuthenticated();
          if(isAuthTokenValid){
            navigate('/');
          }
        } else {
          if (data.emailPassCodeSent) {
            setState({
              isEmailOTPSent: true,
              showEmailOTPSentMessage: true
            });
          } else {
            setSession(null)
            setState({
              errorMessage: data.message ?? 'Failed request',
              error: true,
            });
          }
        }
      }
    }
  }, [loginUser, setState, client, navigate, setGlobalState])

  const processAcceptInvitation = useCallback(async function processAcceptInvitation(setSubmitting, finalData, email, password, emailPassCode) {
    setSubmitting(true)
    const result = await acceptUserInvitation({ variables: finalData })
    setSubmitting(false)
    if (result && result.data && result.data.acceptUserInvitation) {
      if (result.data.acceptUserInvitation.success === true) {
        setState({
          error: false,
          errorMessage: undefined,
          success: true,
          successMessage: 'Your account setup is successful',
        });
        await loginUserCallback(setSubmitting, email, password, emailPassCode);
      } else {
        setState({
          error: true,
          errorMessage: Object.values(result.data)[0] && Object.values(result.data)[0].message ? Object.values(result.data)[0].message : 'Failed Request',
          success: false,
          successMessage: undefined,
        })
      }
    }
    // eslint-disable-next-line
  }, [acceptUserInvitation])

  const formikOptions = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    initialValues: {
      email: initialValues.email || '',
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      password: initialValues.password || '',
      telephone: initialValues.telephone || '',
      rePassword: '',
      newPassword: '',
      emailPassCode: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const finalData = pick(values, 'email', 'newPassword', 'firstName', 'lastName', 'telephone', 'emailPassCode');
      const newPasswordStr = finalData.newPassword;
      finalData.password = initialValues.password;
      finalData.newPassword = btoa(finalData.newPassword);
      setState({
        error: false,
        errorMessage: undefined,
        success: false,
        successMessage: undefined,
      })
      try {
        if (isEmailOTPSent && showEmailOTPSentMessage){
          await loginUserCallback(setSubmitting, finalData.email, newPasswordStr, finalData.emailPassCode);
        } else {
          await processAcceptInvitation(setSubmitting, finalData, finalData.email, newPasswordStr, finalData.emailPassCode);
        }
      } catch (err) {
        console.log(err)
        if (err && err.message) {
          setState({
            error: true,
            errorMessage: err.message,
          })
        }
      }
    },
  });


  useEffect(()=>{
    return ()=>{
      if(timerRef && timerRef.current){
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }
    // eslint-disable-next-line
  }, [])

  return { success, successMessage, error, errorMessage, formikOptions, isEmailOTPSent, showEmailOTPSentMessage }

}


export default useAcceptInvitationForm
