import React from 'react'
import {
  Route,
  Navigate,
  Routes,
  useLocation,
} from 'react-router-dom'
import { isUserAuthenticated } from '../helpers/authUtils'
import InvitationContainer from '../pages/account/InvitationContainer'
import DashboardCompany from '../pages/dashboards/Company'
import DashboardEmptyCompany from '../pages/dashboards/EmptyCompany'
import AuthLayout from '../components/AuthLayout'
import NonAuthLayout from '../components/NonAuthLayout'
import NotFoundPage from '../pages/NotFoundPage'

// Admin
const AdminManage = React.lazy(() => import('../pages/admin/ManageAdmin'))

const DashboardManage = React.lazy(() => import('../pages/manage/Manage'))

const ReportViews = React.lazy(() => import('../pages/report-views/ReportView'))


// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
const ForgotPassword = React.lazy(() => import('../pages/account/ForgotPassword'))
const ConfirmAccount = React.lazy(() => import('../pages/account/Confirm'))
const ConfirmPassword = React.lazy(() => import('../pages/account/ConfirmPasswordContainer'))
const AcknowledgeIncidentByLink = React.lazy(() => import('../pages/AcknowledgeIncidentByLink'))

// dashboards
const CompanyAssets = React.lazy(() => import('../pages/dashboards/Assets'))
const CompanyIncidents = React.lazy(() => import('../pages/dashboards/Incidents'))
const CompanyDevices = React.lazy(() => import('../pages/dashboards/Devices'))
const CompanyReport = React.lazy(() => import('../pages/dashboards/Company/CompanyReport'))
const DashboardAsset = React.lazy(() => import('../pages/dashboards/Asset'))
const DashboardSite = React.lazy(() => import('../pages/dashboards/Site'))
const DashboardZone = React.lazy(() => import('../pages/dashboards/Zone'))
const DashboardIncident = React.lazy(() => import('../pages/dashboards/Incident'))
const DashboardDevice = React.lazy(() => import('../pages/dashboards/Device'))
const LogisticsDashboard = React.lazy(() => import('../pages/dashboards/Logistics'))
const ComplianceDashboard = React.lazy(() => import('../pages/dashboards/Compliance'))


const RouteWrapper = React.memo(({ isPrivatePath, children }) => {
  const location = useLocation();

  if (isUserAuthenticated()) {
    return (<AuthLayout>
      {children}
    </AuthLayout>)
  }

  if (isPrivatePath) {
    return (<Navigate
      to={'/login'}
      replace
      state={{ from: location.pathname, search: location.search }}
    />)
  }

  return (<NonAuthLayout>
    {children}
  </NonAuthLayout>)

})

const RenderRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate replace to='/company/' />} />
      <Route path='/login' element={<RouteWrapper><Login /></RouteWrapper>} />
      <Route path='/logout' element={<RouteWrapper><Logout /></RouteWrapper>} />
      <Route path='/ack_incident_by_link/:linkId' element={
        <RouteWrapper><AcknowledgeIncidentByLink /></RouteWrapper>} />
      <Route path='/forgot-password'
             element={<RouteWrapper><ForgotPassword /></RouteWrapper>} />
      <Route path='/confirm-password/:userName/:token/:token_uuid'
             element={<RouteWrapper><ConfirmPassword /></RouteWrapper>} />
      <Route path='/confirm'
             element={<RouteWrapper><ConfirmAccount /></RouteWrapper>} />
      <Route path='/accept-invitation/:userName/:token'
             element={<RouteWrapper><InvitationContainer /></RouteWrapper>} />
      <Route path='/company/:companyId/assets' element={<RouteWrapper
        isPrivatePath><CompanyAssets /></RouteWrapper>} />
      <Route path='/company/:companyId/asset/:assetId' element={<RouteWrapper
        isPrivatePath><DashboardAsset /></RouteWrapper>} />
      <Route path='/company/:companyId/site/:siteId' element={<RouteWrapper
        isPrivatePath><DashboardSite /></RouteWrapper>} />
      <Route path='/company/:companyId/zone/:zoneId' element={<RouteWrapper
        isPrivatePath><DashboardZone /></RouteWrapper>} />
      <Route path='/company/:companyId/devices' element={<RouteWrapper
        isPrivatePath><CompanyDevices /></RouteWrapper>} />
      <Route path='/company/:companyId/device/:deviceId' element={<RouteWrapper
        isPrivatePath><DashboardDevice /></RouteWrapper>} />
      <Route path='/company/:companyId/incidents' element={<RouteWrapper
        isPrivatePath><CompanyIncidents /></RouteWrapper>} />
      <Route path='/company/:companyId/incident/:incidentId'
             element={<RouteWrapper
               isPrivatePath><DashboardIncident /></RouteWrapper>} />
      <Route path='/company/:companyId/report' element={<RouteWrapper
        isPrivatePath><CompanyReport /></RouteWrapper>} />

      <Route path='/company/:companyId/logistics'
             element={<RouteWrapper
               isPrivatePath><LogisticsDashboard /></RouteWrapper>} />

      <Route path='/company/:companyId/compliance'
             element={<RouteWrapper
               isPrivatePath><ComplianceDashboard /></RouteWrapper>} />
      <Route path='/company/:companyId' element={<RouteWrapper
        isPrivatePath><DashboardCompany /></RouteWrapper>} />
      <Route path='/company' element={<RouteWrapper
        isPrivatePath><DashboardEmptyCompany /></RouteWrapper>} />


      <Route path='/manage/company/:companyId/*' element={<RouteWrapper
        roles={['GLOBAL_ADMIN', 'COMPANY_SUPERVISOR', 'COMPANY_ADMIN', 'COMPANY_VIEW', 'SITE_ADMIN', 'SITE_VIEW', 'ZONE_ADMIN', 'ZONE_VIEW', 'REPORT']}
        isPrivatePath><DashboardManage /></RouteWrapper>} />

      <Route path='/reports-view/*' element={<RouteWrapper
        roles={['GLOBAL_ADMIN', 'REPORT']}
        isPrivatePath><ReportViews /></RouteWrapper>} />


      <Route path='/admin/manage//*' element={<RouteWrapper
        roles={['GLOBAL_ADMIN']}
        isPrivatePath><AdminManage /></RouteWrapper>} />

      <Route path='*'
             element={<RouteWrapper> <NotFoundPage /> </RouteWrapper>} />
    </Routes>
  )

}


export {  RenderRoutes }
