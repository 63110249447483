import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Container } from 'reactstrap'
import { capitalize, sortBy, toUpper } from 'lodash'
import NotificationDropdown from './NotificationDropdown'
import ProfileDropdown from './ProfileDropdown'
import logo from '../assets/images/logo-dark.png'
import logoSmall from '../assets/images/logo-sm.png'
import profilePic from '../assets/images/users/user-1.jpg'
import BreadcrumbComponent from './BreadcrumbComponent'
import GlobalContext from '../providers/GlobalContext'
import {
  GET_COMPANIES_LOOKUP_QUERY,
} from '../constants/queries/lookup'
import useProgressiveDataLoader from '../hooks/useProgressiveDataLoader'
import ChangePassword from '../pages/account/change-password/ChangePassword'
import UpdateProfile from '../pages/account/update-profile/UpdateProfile'
import EndUserAgreementAcceptDialogue
  from './dialogue-forms/EndUserAgreementAcceptDialogue'
import { isAfter } from 'date-fns'
import { toast } from 'react-toastify'
import { useDebouncedEffect } from '../hooks'


const useStyles = createUseStyles({
  breadcrumbBox: {
    padding: {
      top: 13,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
})


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useTopBar() {

  const navigate = useNavigate()

  const {
    selectedCompany,
    loggedInUser,
    setShowGlobalSearchBar,
    selectActiveSessionCompany,
  } = React.useContext(GlobalContext)

  const {
    fetchData: fetchCompaniesData,
    data: companyData,
  } = useProgressiveDataLoader({
    query: GET_COMPANIES_LOOKUP_QUERY,
    isImmediate: false,
  })


  const [{
    companies,
    showEndUserAgreementForm,
    showChangePasswordForm,
    showUpdateProfileForm,
  }, setState] = useReducer(reducer, {
    companies: undefined,
    showChangePasswordForm: undefined,
    showUpdateProfileForm: undefined,
    showEndUserAgreementForm: false,
  })

  const selectActiveCompany = useCallback(async (selectedCompanyId) => {
    if (['GLOBAL_ADMIN', 'REPORT'].includes(loggedInUser.role)) {
      navigate(`/company/${selectedCompanyId}`)
    } else {
      const results = await selectActiveSessionCompany(selectedCompanyId)
      if (results === true) {
        navigate(`/company/${selectedCompanyId}`)
      }
    }
  }, [selectActiveSessionCompany, loggedInUser, navigate])

  useDebouncedEffect(() => {
    if (loggedInUser) {
      fetchCompaniesData({ variables: {} })
      const passWordExpiresAt = new Date(loggedInUser.passwordExpiresAt * 1000)
      let _showChangePasswordForm = false

      if (!['GLOBAL_ADMIN', 'REPORT'].includes(loggedInUser.role)) {
        _showChangePasswordForm = isAfter(new Date(), passWordExpiresAt)
      }

      if (!loggedInUser.isEndUserAgreementAccepted) {
        setState({
          showEndUserAgreementForm: true,
        })
      } else {
        if (_showChangePasswordForm) {
          toast(`Your password has been expired. Please change your password now.`, {
            type: toast.TYPE.WARNING,
          })
        }
        const timer = setTimeout(() => {
          setState({
            showEndUserAgreementForm: false,
            showChangePasswordForm: _showChangePasswordForm,
          })
        }, 5000)
        return () => clearTimeout(timer)
      }
    }
    // eslint-disable-next-line
  }, 900, [loggedInUser])

  useEffect(() => {
    if (companyData && Array.isArray(companyData)) {
      setState({
        companies: sortBy(companyData, 'name'),
      })
      return () => setState({ companies: [] })
    }
  }, [companyData])

  const ProfileMenus = useMemo(() => {
    let id = 1
    const menueItems = []
    if (loggedInUser) {
      menueItems.push({
        id: id++,
        label: 'Update Profile',
        hasDivider: false,
        onClick: () => {
          setState({
            showUpdateProfileForm: true,
          })
        },
      })

      menueItems.push({
        id: id++,
        label: 'Change Password',
        hasDivider: false,
        onClick: function() {
          setState({
            showChangePasswordForm: true,
          })
        },
      })
    }

    //&& loggedInUser.role === 'GLOBAL_ADMIN'
    if (loggedInUser && loggedInUser.role && companies && Array.isArray(companies) && companies.length > 1) {
      const companyMenu = {
        id: id++,
        label: 'Switch Company',
        icon: 'fe-log-out',
        hasDivider: false,
      }
      companyMenu.children = []
      for (const company of companies) {
        companyMenu.children.push({
          id: id++,
          label: company.name,
          onClick: () => {
            selectActiveCompany(company.id)
          },
          //icon: 'fe-log-out',
          // redirectTo: `/company/${company.id}`,
        })
      }
      menueItems.push(companyMenu)
    }

    menueItems.push({
      id: id++,
      label: 'Documentation',
      icon: 'fe-book',
      target: '_blank',
      redirectTo: 'https://docs.sematics.com',
      hasDivider: false,
    })

    menueItems.push({
      id: id++,
      label: 'Logout',
      icon: 'fe-log-out',
      redirectTo: '/logout',
      hasDivider: false,
    })
    return menueItems

  }, [loggedInUser, companies, selectActiveCompany])


  const onEndUserAgreementConfirm = useCallback(() => {
    setState({
      showEndUserAgreementForm: false,
    })
  }, [])


  return {
    loggedInUser,
    selectedCompany,
    ProfileMenus,
    showChangePasswordForm,
    showUpdateProfileForm,
    setShowGlobalSearchBar,
    setState,
    showEndUserAgreementForm,
    onEndUserAgreementConfirm,
  }
}

const Topbar = React.memo(function Topbar(props) {

  const classes = useStyles()

  const {
    showEndUserAgreementForm,
    onEndUserAgreementConfirm,
    loggedInUser,
    selectedCompany,
    ProfileMenus,
    showChangePasswordForm,
    showUpdateProfileForm,
    setShowGlobalSearchBar,
    setState,
  } = useTopBar()

  if (!loggedInUser) {
    return null
  }

  return (
    <React.Fragment>
      <div className='navbar-custom'>
        <Container fluid>
          <ul className='list-unstyled topnav-menu float-right mb-0'>
            <li
              className='nav-link dropdown-toggle waves-effect waves-light btn btn-link'>
              <Link to={() => {
              }} onClick={() => setShowGlobalSearchBar(true)}
                    className='breadcrumb-item active'>
                <span> <i className='icon search'
                          style={{ marginTop: '2px' }} /></span>
              </Link>
            </li>
            <li>
              {selectedCompany && selectedCompany.id && (
                <NotificationDropdown companyId={selectedCompany.id} />
              )}

            </li>
            {loggedInUser && loggedInUser.role && (loggedInUser.role === 'GLOBAL_ADMIN' || loggedInUser.role === 'COMPANY_SUPERVISOR' || loggedInUser.role === 'COMPANY_ADMIN' || loggedInUser.role === 'SITE_ADMIN' || loggedInUser.role === 'ZONE_ADMIN') ? (
              <li
                className='nav-link dropdown-toggle waves-effect waves-light btn btn-link'>
                <Link className='breadcrumb-item active'
                      to={selectedCompany && selectedCompany.id ? `/manage/company/${selectedCompany.id}/domain` : '/manage/company/2/domain'}><span><i
                  className='fe-settings manage-icon' /></span></Link>
              </li>
            ) : ''}
            {loggedInUser && loggedInUser.role && loggedInUser.role === 'GLOBAL_ADMIN' ? (
              <li
                className='nav-link dropdown-toggle waves-effect waves-light btn btn-link'>
                <Link title='Admin Settings' className='breadcrumb-item active'
                      to='/admin/manage'><span><i
                  className='fe-sliders manage-icon' /></span></Link>
              </li>
            ) : ''}
            {/*
              <li className="nav-link dropdown-toggle waves-effect waves-light btn btn-link">
                <span> {loggedInUser && loggedInUser.firstName && loggedInUser.lastName ? [loggedInUser.firstName , loggedInUser.lastName ].join(' '): ''}</span>
              </li>
              */}
            <li>
              <ProfileDropdown
                profilePic={profilePic}
                menuItems={ProfileMenus}
                username={loggedInUser.firstName && loggedInUser.lastName ? capitalize(loggedInUser.firstName.split('')[0]) + capitalize(loggedInUser.lastName.split('')[0]) : toUpper(loggedInUser.email?.substring(0, 2))}
              />
            </li>
          </ul>
          <div className='logo-box'>
            <Link
              to={selectedCompany && selectedCompany.id ? `/company/${selectedCompany.id}` : '/'}
              className='logo text-center'>
                <span className='logo-lg'>
                  <img src={logo} alt='' height='25' />
                </span>
              <span className='logo-sm'>
                  <img src={logoSmall} alt='' height='25' />
                </span>
            </Link>
          </div>
          <div className={classes.breadcrumbBox}>
            <BreadcrumbComponent />
          </div>
        </Container>
      </div>
      {showChangePasswordForm && (
        <ChangePassword isOpen={showChangePasswordForm}
                        setIsOpen={(val) => setState({
                          showChangePasswordForm: val,
                        })} />
      )}
      {showUpdateProfileForm && (
        <UpdateProfile isOpen={showUpdateProfileForm}
                       setIsOpen={(val) => setState({
                         showUpdateProfileForm: val,
                       })} />
      )}
      {showEndUserAgreementForm && (
        <EndUserAgreementAcceptDialogue
          onConfirm={onEndUserAgreementConfirm}
          isOpen={showEndUserAgreementForm} />
      )}
    </React.Fragment>
  )
})

export default Topbar
