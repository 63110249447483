import React, { useEffect } from "react";
import {
  useParams,
  useNavigate
} from 'react-router-dom'
import GlobalContext from "../../providers/GlobalContext";


function useEmptyCompanyOverview() {
  const navigate = useNavigate();
  const params = useParams();

  const {
    loggedInUser,
  } = React.useContext(GlobalContext);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.companyId && parseInt(loggedInUser.companyId) > 0) {
        navigate(`/company/${loggedInUser.companyId}`);
      } else {
        navigate("/company/2");
      }
    }
    // eslint-disable-next-line
  }, [params, loggedInUser]);


  return {
    loggedInUser,
  };

}


const DashboardEmptyCompany = React.memo(() => {

 useEmptyCompanyOverview();

  return null
});

export default DashboardEmptyCompany;
