import React, { useCallback, useEffect, useReducer } from 'react';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { setSession } from '../helpers/authUtils';
import { GET_LOGGED_IN_USER_QUERY } from '../constants/queries/dashboard';
import GlobalContext from './GlobalContext';
import {
  AUTH_LOGOUT_MUTATION,
  SET_ACTIVE_USER_SESSION_MUTATION
} from '../constants/mutations/auth';
import { useDebouncedEffect } from '../hooks';
import commonReducer from '../common/commonReducer';


function useGlobalContextProvider() {

  const client = useApolloClient();

  const [{
    selectedCompany,
    selectedCompanyId,
    loggedInUser,
    showGlobalSearchBar,
    showGlobalCustomReportModal
  }, setState] = useReducer(commonReducer, {
    selectedCompany: undefined,
    selectedCompanyId: undefined,
    loggedInUser: undefined,
    showGlobalSearchBar: false,
    showGlobalCustomReportModal: false
  });

  const [setActiveUserSession] = useMutation(SET_ACTIVE_USER_SESSION_MUTATION);

  const [logoutUserRequest] = useMutation(AUTH_LOGOUT_MUTATION);

  const [getLoggedInUserQuery, {
    data: userData,
    error,
    loading: loggedInUserLoading
  }] = useLazyQuery(GET_LOGGED_IN_USER_QUERY, { fetchPolicy: 'no-cache' });

  const loadLoggedInUser = useCallback(async () => {
    try {
      getLoggedInUserQuery({
        variables: {}
      }).then(result=>{
        if (!result?.data?.me){
          clearSession();
        }
      }).catch(err => {
        clearSession();
      });
    } catch (err) {
      clearSession();
    }
    // eslint-disable-next-line
  }, [getLoggedInUserQuery]);


  const clearSession = useCallback(() => {
    setSession(undefined);
    setState({
      loggedInUser: undefined
    });
    // eslint-disable-next-line no-unused-expressions
    sessionStorage && sessionStorage.clear();
  }, []);


  const logoutUser = useCallback(async () => {
    if (loggedInUser) {
      try {
        const result = await logoutUserRequest({
          variables: {
            id: loggedInUser.id
          }
        });
        if (result && result.data && Object.values(result.data)[0]) {
          if (Object.values(result.data)[0].success !== true) {
            console.log('Logout was not successful', Object.values(result.data)[0]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    clearSession();
  }, [loggedInUser, clearSession, logoutUserRequest]);


  useDebouncedEffect(() => {
    if (userData) {
      if (userData.me) {
        setState({
          loggedInUser: { ...userData.me }
        });
        return () => {
          setState({
            loggedInUser: undefined
          });
        };
      } else {
        clearSession();
      }
    }
    // eslint-disable-next-line
  }, 100, [userData]);


  useEffect(() => {
    if (error) {
      if (error.message.includes('UNAUTHORIZED')) {
        setSession(undefined);
        setState({
          loggedInUser: undefined
        });
      }
    }
  }, [error]);

  const selectActiveSessionCompany = useCallback(async (selectedCompanyId) => {
    if (selectedCompanyId) {
      await client.clearStore();
      const result = await setActiveUserSession({
        variables: {
          companyId: selectedCompanyId
        }
      });
      if (result && result.data) {
        if (result.data.setActiveUserSession) {
          const data = result.data.setActiveUserSession;
          if (data.success) {
            setSession(data);
            setState({
              loggedInUser: { ...data.user }
            });
            return true;
          }
        }
      }
    }
    return false;
  }, [setActiveUserSession, client]);


  const setShowGlobalSearchBar = useCallback((val) => {
    setState({
      showGlobalSearchBar: val
    });
  }, []);

  const setShowGlobalCustomReportModal = useCallback((val) => {
    setState({
      showGlobalCustomReportModal: val
    });
  }, []);


  return {
    selectedCompany,
    selectedCompanyId,
    loggedInUser,
    loadLoggedInUser,
    showGlobalSearchBar,
    showGlobalCustomReportModal,
    setShowGlobalSearchBar,
    setShowGlobalCustomReportModal,
    clearSession,
    setState,
    selectActiveSessionCompany,
    logoutUser,
    loggedInUserLoading,
    getLoggedInUserQuery
  };

}

const GlobalContextProvider = React.memo(({ children }) => {

  const {
    selectedCompany,
    selectedCompanyId,
    loggedInUser,
    loadLoggedInUser,
    setShowGlobalSearchBar,
    showGlobalSearchBar,
    clearSession,
    setState,
    showGlobalCustomReportModal,
    setShowGlobalCustomReportModal,
    selectActiveSessionCompany,
    logoutUser,
    getLoggedInUserQuery,
    loggedInUserLoading
  } = useGlobalContextProvider({});

  return (<GlobalContext.Provider value={{
    selectedCompany,
    selectedCompanyId,
    loggedInUser,
    setGlobalState: setState,
    clearSession,
    showGlobalSearchBar,
    logoutUser,
    setShowGlobalSearchBar,
    loadLoggedInUser,
    showGlobalCustomReportModal,
    setShowGlobalCustomReportModal,
    selectActiveSessionCompany,
    loggedInUserLoading,
    getLoggedInUserQuery
  }}>
    {children}
  </GlobalContext.Provider>);
});


export default GlobalContextProvider;
