import invariant from 'invariant'
import { isString, memoize, uniqBy } from 'lodash';
// escape RegExp special characters https://stackoverflow.com/a/9310752/5142490
const escapeRegex = str => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

const PLACEHOLDERS = {
  id: '__id__',
  display: '__display__',
}
const emptyFn = () => {
}

const countPlaceholders = markup => {
  let count = 0
  if (markup.indexOf('__id__') >= 0) count++
  if (markup.indexOf('__display__') >= 0) count++
  return count
}

const combineRegExps = regExps => {
  const serializedRegexParser = /^\/(.+)\/(\w+)?$/
  return new RegExp(
    regExps
      .map(regex => {
        const [, regexString, regexFlags] = serializedRegexParser.exec(
          regex.toString(),
        )

        invariant(
          !regexFlags,
          `RegExp flags are not supported. Change /${regexString}/${regexFlags} into /${regexString}/`,
        )

        return `(${regexString})`
      })
      .join('|'),
    'g',
  )
}


export const markupToRegex = markup => {
  const escapedMarkup = escapeRegex(markup)
  const charAfterDisplay =
    markup[markup.indexOf(PLACEHOLDERS.display) + PLACEHOLDERS.display.length]
  const charAfterId =
    markup[markup.indexOf(PLACEHOLDERS.id) + PLACEHOLDERS.id.length]
  return new RegExp(
    escapedMarkup
      .replace(
        PLACEHOLDERS.display,
        `([^${escapeRegex(charAfterDisplay || '')}]+?)`,
      )
      .replace(PLACEHOLDERS.id, `([^${escapeRegex(charAfterId || '')}]+?)`),
  )
}

export const UserMentionMarkup = '@[__display__](user:__id__)'

export const DefaultDisplayTransform = (id, display) => `@${display}`

const config = [{
  markup: UserMentionMarkup,
  regex: markupToRegex(UserMentionMarkup),
  displayTransform: DefaultDisplayTransform,
}]


export const transformMentionText = memoize((text) => {
  if (isString(text) && text.length > 0) {
    const _regex = combineRegExps(config.map(c => c.regex))
    const resp = text.replace(_regex, (match, prefix, mention) => {
      return `@${mention}`
    })
    return resp
  }
  return text
})


const findPositionOfCapturingGroup = (markup, parameterName) => {
  invariant(
    parameterName === 'id' || parameterName === 'display',
    `Second arg must be either "id" or "display", got: "${parameterName}"`,
  )
  let indexDisplay = markup.indexOf(PLACEHOLDERS.display);
  let indexId = markup.indexOf(PLACEHOLDERS.id);
  // set indices to null if not found
  if (indexDisplay < 0) indexDisplay = null;
  if (indexId < 0) indexId = null;
  // markup must contain one of the mandatory placeholders
  invariant(
    indexDisplay !== null || indexId !== null,
    `The markup '${markup}' does not contain either of the placeholders '__id__' or '__display__'`,
  )
  if (indexDisplay !== null && indexId !== null) {
    return (parameterName === 'id' && indexId <= indexDisplay) ||
    (parameterName === 'display' && indexDisplay <= indexId)
      ? 0
      : 1;
  }
  // just one placeholder is being used, we'll use the captured string for both parameters
  return 0
}

const iterateMentionsMarkup = (
  value,
  config,
  markupIteratee,
  textIteratee = emptyFn,
) => {
  const regex = combineRegExps(config.map(c => c.regex))
  let accOffset = 2 // first is whole match, second is the for the capturing group of first regexp component
  const captureGroupOffsets = config.map(({ markup }) => {
    const result = accOffset
    // + 1 is for the capturing group we add around each regexp component in combineRegExps
    accOffset += countPlaceholders(markup) + 1
    return result
  })

  let match
  let start = 0
  let currentPlainTextIndex = 0
  // detect all mention markup occurrences in the value and iterate the matches
  while ((match = regex.exec(value)) !== null) {
    const offset = captureGroupOffsets.find(o => !!match[o]) // eslint-disable-line no-loop-func
    const mentionChildIndex = captureGroupOffsets.indexOf(offset)
    const { markup, displayTransform } = config[mentionChildIndex]
    const idPos = offset + findPositionOfCapturingGroup(markup, 'id')
    const displayPos = offset + findPositionOfCapturingGroup(markup, 'display')
    const id = match[idPos]
    const display = displayTransform(id, match[displayPos])

    let substr = value.substring(start, match.index)
    textIteratee(substr, start, currentPlainTextIndex)
    currentPlainTextIndex += substr.length

    markupIteratee(
      match[0],
      match.index,
      currentPlainTextIndex,
      id,
      display,
      mentionChildIndex,
      start,
    )
    currentPlainTextIndex += display.length
    start = regex.lastIndex
  }

  if (start < value.length) {
    textIteratee(value.substring(start), start, currentPlainTextIndex)
  }
}


export const getMentions = (value, userParticipantsData= []) => {
  const mentions = [];
  iterateMentionsMarkup(
    value,
    config,
    (match, index, plainTextIndex, id, display, childIndex, start) => {
      mentions.push({
        id: id,
        display: display,
        childIndex: childIndex,
        index: index,
        plainTextIndex: plainTextIndex,
      })
    },
  )
  if (/@\b(here)\b/gi.test(value)){
    userParticipantsData.forEach((item)=> {
      mentions.push({
        id: item.id,
        display: [item.firstName, item.lastName].join(' '),
      });
    });
  }
  return uniqBy(mentions, 'id');
}
