import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'
import { Alert, Button, Col, Container, Row } from 'reactstrap'
import useSematicsGoogleMap from './useSematicsGoogleMap'
import HoveredSiteContext from '../HoveredSiteContext'
import TinyWeatherInfo from '../TinyWeatherInfo'

const MapStyle = require("./MapStyle.json");

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

const CompanyMap = React.memo(({ sites, companyId }) => {

  const {
    mapRef,
    isMapLoaded,
    mapReady,
    onMapClick,
    onMapLoadCallback,
    DefaultConfig,
    onMarkerClick,
    selectedMarkerData,
    onMarkerClose
  } = useSematicsGoogleMap({});
  const [{
    markers,
    defaultZoom,
    defaultCenter
  }, setState] = useReducer(reducer, {
    markers: [],
    defaultZoom: DefaultConfig.defaultZoom,
    defaultCenter: DefaultConfig.defaultCenter
  });

  const hoveredSite = useContext(HoveredSiteContext);

  const mapPopupData = useMemo(() => (hoveredSite && markers.find(m => m.data.id === (hoveredSite.parentId || hoveredSite.id))) || selectedMarkerData, [hoveredSite, markers, selectedMarkerData]);

  useEffect(() => {
    if (hoveredSite && selectedMarkerData) {
      onMarkerClose();
    }
    // eslint-disable-next-line
  }, [hoveredSite, selectedMarkerData]);

  useEffect(() => {
    if (sites && sites.length && mapReady) {
      const _markers = [];
      let hasBounds = false;
      const _bounds = new window.google.maps.LatLngBounds();
      sites.forEach((item) => {
        if (item && (item.lat || item.lng)) {
          _bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
          const ___newMarker = {
            position: {
              lat: item.lat,
              lng: item.lng
            },
            label: {
              fontFamily: "Material Design Icons",
              fontSize: "16px",
              text: "\uf990",
              color: "white"
            },
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 11,
              fillColor: item.stats?.incidents.open > 0 ? "#DB2828" : (item.stats?.warnings && item.stats?.warnings?.count > 0 ? "#F2711C" : "#21BA45"),
              fillOpacity: 1,
              strokeWeight: 0.4
            },
            selectedItemColor: item.stats?.incidents.open > 0 ? "danger" : (item.stats?.warnings && item.stats?.warnings?.count > 0 ? "warning" : "success"),
            data: item
          };
          hasBounds = true;
          _markers.push(___newMarker);
        }
      });
      setState({
        markers: _markers
      });
      if (mapRef.current) {
        if (hasBounds) {
          if (sites.length === 1) {
            setState({
              defaultZoom: 17,
              defaultCenter: _bounds.getCenter() || DefaultConfig.defaultCenter
            });
            mapRef.current.setCenter(_bounds.getCenter() || DefaultConfig.defaultCenter);
            mapRef.current.setZoom(17);
          } else {
            mapRef.current.fitBounds(_bounds);
            setState({
              defaultZoom: DefaultConfig.defaultZoom,
              defaultCenter: _bounds.getCenter() || DefaultConfig.defaultCenter
            });
          }
        } else {
          setState({
            defaultZoom: DefaultConfig.defaultZoom,
            defaultCenter: DefaultConfig.defaultCenter
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [sites, mapRef.current, mapReady, DefaultConfig]);


  return (
    <div style={{ minHeight: `412px`, height: "100%", width: `100%` }}>
      {isMapLoaded && (
        <GoogleMap
          mapContainerStyle={{ height: `412px`, width: `100%` }}
          id="sematics-company-overview-map"
          onLoad={onMapLoadCallback}
          zoom={defaultZoom}
          center={defaultCenter}
          onClick={onMapClick}
          options={{
            styles: MapStyle,
            scaleControl: true,
            streetViewControl: false,
            mapTypeId: "satellite"
          }}
        >
          {markers && markers.map((item, i) => (
            <Marker key={`marker-${i}`}
                    onClick={() => onMarkerClick(item)}
                    {...item}
            />
          ))}
          {mapPopupData && (
            <InfoWindow
              onCloseClick={onMarkerClose}
              position={mapPopupData.position}
            >
              <div style={DefaultConfig.infoDivStyle}>
                <Alert color={mapPopupData.selectedItemColor}
                       className="font-weight-bold mb-0 p-2" fade={false}>
                  <Link
                    to={`/company/${companyId}/site/${mapPopupData.data.id}`}>{mapPopupData.data && mapPopupData.data.name}</Link>
                  <div className="mt-n1 float-right">
                    <Button size="sm" close  onClick={onMarkerClose} />
                  </div>
                </Alert>
                <Container>
                 <Row className="ml-0 mr-0 text-center">
                  <Col className="border-right pt-3 pb-1">
                    <span><i
                      className="icon cubes" />{mapPopupData.data && mapPopupData.data.assets.count}</span>
                    <p>Assets</p>
                  </Col>
                  <Col className="border-right pt-3 pb-1">
                    <TinyWeatherInfo data={mapPopupData.data?.currentWeather} />
                    <p>Weather</p>
                  </Col>
                  <Col className="pt-3 pb-1">
                    <span><i
                      className="icon bell" />{mapPopupData.data && mapPopupData.data.stats?.incidents?.open}</span>
                    <p>Incidents</p>
                  </Col>
                </Row>
                </Container>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );

});

export default CompanyMap;
