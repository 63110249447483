import React, { useCallback, useContext } from 'react'
import styled from "styled-components";

import SpotlightContext from "./SpotlightContext";

const Input = styled.input`
  margin: 0;
  padding: 0;
  height: 60px;
  font-size: 1.7em;
  font-weight: 100;
  padding-left: 55px;
  padding-right: 50px;
  box-sizing: border-box;
  border: none !important;
  outline: none !important;
  max-width: 100% !important;
  width: 100%;
  background-color: transparent;

  &:-ms-input-placeholder {
    color: #a6a6a6;
  }
  &:-moz-placeholder {
    color: #a6a6a6;
  }
  &::-moz-placeholder {
    color: #a6a6a6;
  }
  &::-webkit-input-placeholder {
    color: #a6a6a6;
  }
`;

const SearchInput = React.memo((props)=>{

  const {
    handleKeyDown,
    performSearch} = useContext(SpotlightContext);


  const onSearchCallback = useCallback((event)=>{
    performSearch(event.target.value);
  }, [performSearch]);


  return (
    <Input
      autoFocus
      placeholder="Search"
      onKeyUp={onSearchCallback}
      onKeyDown={handleKeyDown}
    />
  );

})

export default SearchInput;
